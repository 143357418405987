<template>
  <div class="content">

   <!-- Modal para editar -->
   <b-modal v-model="modal" title="Título del Modal" @hide="cerrarModal">
      <template #modal-header>
        <h3 class="modal-title"><b>{{ tituloModal }}</b></h3>
        <b-button @click="cerrarModal()" variant="close"></b-button>
      </template>

      <div class="modal-body">
        <div>
          <label for="nombre">Nombre</label>
          <input v-model="usuario.nombre" type="text" class="form-control" id="nombre" placeholder="Nombre">
        </div>

        <div>
          <label for="apellido_paterno">Apellido paterno</label>
          <input v-model="usuario.apellido_paterno" type="text" class="form-control" id="apellido_paterno" placeholder="Apellido Paterno">
        </div>

        <div>
          <label for="apellido_materno">Apellido materno</label>
          <input v-model="usuario.apellido_materno" type="text" class="form-control" id="apellido_materno" placeholder="Apellido Materno">
        </div>

        <div>
          <label for="tipo_documento">Tipo de documento</label>
          <b-form-select name="codigo_tipo_documento" id="codigo_tipo_documento" v-model="usuario.codigo_tipo_documento" :options="options_documento" required class="form-control"></b-form-select>
        </div>

        <div>
          <label for="numero_documento">Número Documento</label>
          <input v-model="usuario.numero_documento" type="text" class="form-control" id="numero_documento" placeholder="Número Documento">
        </div>

        <div>
          <label for="tipo_usuario">Tipo de Usuario</label>
          <b-form-select name="codigo_tipo_usuario" id="codigo_tipo_usuario" v-model="usuario.codigo_tipo_usuario" :options="options_usuario" required class="form-control"></b-form-select>
        </div>
        
        <div v-if="editar">
          <label for="email">Usuario</label>
          <input v-model="usuario.email" type="text" class="form-control" id="email" :readonly="true">
        </div>

        <div v-if="agregar">
          <label for="email">Usuario</label>
          <input v-model="usuario.email2" type="text" class="form-control" id="email2" placeholder="@">
        </div>

        <div>
          <input type="hidden" id="password" name="password" v-model="usuario.password">
          <b-form-input name="password2" id="password2" type="password" placeholder="***********" v-model="usuario.password2" required></b-form-input>
        </div>
      </div>

      <template #modal-footer>

          <b-button @click="add_edit_usuario()" variant="success" data-mismiss="modal">Guardar</b-button>
       
      </template>
    </b-modal>
    <!-- end -->

    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
         <br>
         <div class="d-flex">
           <div class="lista-usuario-input" style="margin-right: 15px;">
              <input type="text" v-model="search" placeholder="Buscar ..." class="form-control lista-usuario-ipt">
           </div>
          <div style="margin-right: 15px;">
            <label for="estado">Estado: </label>
          </div>
          <div style="margin-right: 15px;">
            <select v-model="estado" class="form-control">
              <option value="">Todos</option>
              <option value="1">Activado</option>
              <option value="2">Desactivado</option>
            </select>
          </div>
          <div>
            <b-button @click="modificar=false; abrirModal()" type="button" class="btn btn-primary">Agregar usuario</b-button>
          </div>
        </div>
         <br><br>
          <md-card-header data-background-color="green">
            <h4 class="title">Lista de usuarios</h4>
          </md-card-header>

          <md-table>
            <!-- Encabezado de la tabla -->
            <md-table-row>
              <md-table-head class="header-cell">#</md-table-head>
              <md-table-head class="header-cell">Nombre completos</md-table-head>
              <md-table-head class="header-cell">Documentos</md-table-head>
              <md-table-head class="header-cell">Usuario</md-table-head>
              <md-table-head class="header-cell">Estado</md-table-head>
              <md-table-head class="header-cell"></md-table-head>
              <md-table-head class="header-cell accion-col">Opciones</md-table-head>
              <md-table-head class="header-cell"></md-table-head>

            </md-table-row>

            <!-- listar usuarios con v-for -->

            <md-table-row v-for="(item,index) in paginatedItems" :key="index">
              <md-table-cell>{{item.id}}</md-table-cell>
              <md-table-cell>{{item.nombre}} {{item.apellido_paterno}} {{item.apellido_materno}}</md-table-cell>
              <md-table-cell>{{item.tip_doc}} {{item.numero_documento}}</md-table-cell>
              <md-table-cell>{{item.tip_usu}} {{item.email}}</md-table-cell>
              <md-table-cell>{{item.estado ===1 ? 'Activado': 'Desactivado'}}</md-table-cell>
              <md-table-cell> <md-button @click="modificar=true; abrirModal(item)" class="md-round md-warning">Editar</md-button></md-table-cell>
              <md-table-cell><md-button @click="activar(item.id)" class="md-round md-success">Activar</md-button></md-table-cell>
              <md-table-cell><md-button @click="desactivar(item.id)" class="md-round md-danger">Desactivar</md-button></md-table-cell>
            </md-table-row>
          </md-table>

          <div class="pagination">
            <button @click="previousPage" :disabled="currentPage === 1" >Anterior</button>
               <span>{{ currentPage }} de {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button>
          </div>

        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';
import { BFormSelect, BFormInput, BButton } from 'bootstrap-vue';


export default {
  
  components: {
    BFormSelect,
    BFormInput,
    BButton
  }, 
  data() {
  return {
   estado: '',
   pageSize:8,
   currentPage:1,
   users:[],
   search:'',
   id: 0,
   usuario:{
     id: '',
     nombre: '',
     apellido_paterno: '',
     apellido_materno: '',
     codigo_tipo_documento: '',
     numero_documento: '',
     codigo_tipo_usuario: '',
     email: '',
     email2: '',
     password: '',
     password2: '',
   },

     nombre: '',
     apellido_paterno: '',
     apellido_materno: '',
     codigo_tipo_documento: '',
     numero_documento: '',
     codigo_tipo_usuario: '',
     email: '',
     email2: '',
     password: '',
     password2: '',

   options_usuario: [
     { value: '1', text: 'ADMINISTRADOR' },
     { value: '2', text: 'VENDEDOR' }
   ],

   options_documento:[
      { value: '1', text: 'DNI' },
      { value: '2', text: 'PASAPORTE' },
      { value: '3', text: 'CARNET DE EXTRANJERIA'}
   ],

   tituloModal:'',
   modal:false,
   modificar:true,
   editar: false,
   agregar: false,
    };
  },

  computed: {

   totalPages() {
    return Math.ceil(this.users.length / this.pageSize);
   },

   paginatedItems() {
    const filteredItems = this.users.filter(user => {
      return( 
        Object.values(user).some(value => 
        value.toString().toLowerCase().includes(this.search.toLowerCase())
      ) && 
      (this.estado === '' || user.estado.toString() === this.estado) 
     );
    });
   
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return filteredItems.slice(startIndex, endIndex);
   }

  },

  methods: {

    async listar() {
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try {
      const params = { estado: this.estado };
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/listar_usuario'; 
      
      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      }, { params });
      
      this.users = res.data.users;
    } catch (error) {
      console.error('Error al listar usuarios:', error);
    }
  },

  previousPage(){
    if(this.currentPage > 1){
      this.currentPage--;
    }
  }, 

  nextPage(){
   if(this.currentPage<this.totalPages){
      this.currentPage++;
   }
  },

  async activar(id) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/usuario_complemento/' + id;

  try {
    const res = await axios.put(link, {
      estado: 1 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Activado',
      text: 'Usuario tiene permiso',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},

async desactivar(id) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/usuario_complemento/' + id;

  try {
    const res = await axios.put(link, {
      estado: 2 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Desactivado',
      text: 'Usuario no tiene permiso',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},

  cerrarModal(){
    this.modal=false;
    this.limpiarCampos();
  },

  limpiarCampos() {
      this.usuario = {
        id: '',
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        codigo_tipo_documento: '',
        numero_documento: '',
        codigo_tipo_usuario: '',
        email: '',
        password: '',
        password2: '',
      };
    },

   async add_edit_usuario (){
     if(this.modificar){
     
     try{

      const user = {
        nombre: this.usuario.nombre,
        apellido_paterno: this.usuario.apellido_paterno,
        apellido_materno: this.usuario.apellido_materno,
        codigo_tipo_documento: this.usuario.codigo_tipo_documento,
        numero_documento: this.usuario.numero_documento,
        codigo_tipo_usuario: this.usuario.codigo_tipo_usuario,
        password: this.usuario.password,
        password2: this.usuario.password2,
      };

      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/usuario/' + this.id;

      const res = await axios.put(link, user, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Actualizado correctamente',
      text: 'Se modifico el usuario correctamente',
    });

  } catch (error) {
    if (error.response && error.response.status === 500) {
     const errors = error.response.data.erros;
     let errorMessage = '';
     for (const key in errors) {
      if (errors.hasOwnProperty(key)){
        errorMessage += `${errors[key][0]}\n`;
      }
     }
     Swal.fire({
      icon: 'error',
      title: '¡Error ya existe ese número de documento!',
      text: errorMessage,
     });

    } else {

      Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error,
     });


    }

  }

  this.cerrarModal();
  this.listar();

     } else {

     try{
       
      const user = {
        nombre: this.usuario.nombre,
        apellido_paterno: this.usuario.apellido_paterno,
        apellido_materno: this.usuario.apellido_materno,
        codigo_tipo_documento: this.usuario.codigo_tipo_documento,
        numero_documento: this.usuario.numero_documento,
        email: this.usuario.email2,
        codigo_tipo_usuario: this.usuario.codigo_tipo_usuario,
        password2: this.usuario.password2,
      };


      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/usuarioAgregar';

      const res = await axios.post(link, user, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Agregado correctamente',
      text: 'Se agrego el usuario correctamente',
    });

     } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data && error.response.data.message ){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
      });

      } else {
        console.error('Error en la solicitud:',error);
      }

     }

     }
     this.cerrarModal();
   },

  abrirModal(data={}){
   this.modal=true;  
   if(this.modificar){
    this.editar = true;
    this.agregar = false;
    this.tituloModal="Modificar Usuario";
    this.id=data.id,
    this.usuario.nombre=data.nombre;
    this.usuario.apellido_paterno=data.apellido_paterno;
    this.usuario.apellido_materno=data.apellido_materno;
    this.usuario.codigo_tipo_documento=data.codigo_tipo_documento;
    this.usuario.numero_documento=data.numero_documento;
    this.usuario.codigo_tipo_usuario=data.codigo_tipo_usuario;
    this.usuario.email=data.email;
    this.usuario.password=data.contra;
    
   }else{
    this.id = 0;
    this.editar = false;
    this.agregar = true;
    this.tituloModal="Agregar Usuario";
    this.usuario.nombre='';
    this.usuario.apellido_paterno='';
    this.usuario.apellido_materno='';
    this.usuario.codigo_tipo_documento='';
    this.usuario.numero_documento='';
    this.usuario.codigo_tipo_usuario='';
    this.usuario.email2='';
    this.usuario.password2='';
   }
  },
  
  },

  created() {
  this.listar();
  this.intervalId = setInterval(this.listar, 5000); //se actualiza cada 5 segundos
},
beforeDestroy() {
  clearInterval(this.intervalId); // Limpiamos el intervalo cuando se destruye el componente
}

};
</script>

<style scoped>
.header-cell {
  color: green !important; /* Asegúrate de que se aplique el color verde */
  font-weight: bold; /* Haz que el texto sea más visible */
}

.pagination button{
  margin:0 5px;
}

.lista-usuario-input{
 display: flex;
 flex-direction: row;
 justify-content: start;
 align-items: center;
 margin-left: 20px; 
}

.lista-usuario-ipt{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  margin-left: 20px; 
  flex: 1; /* Esto permite que el input ocupe el espacio restante */
  min-width: 200px; /* Ajusta el valor según sea necesario */
}

.accion-col {
  text-align: center; /* Centra el contenido horizontalmente */
}

.d-flex {
  display: flex;
  align-items: center; /* Opcional: Alinea verticalmente los elementos en el centro */
}

.d-flex > * {
  margin-right: 10px; /* Añade espacio entre los elementos */
}

.d-flex > *:last-child {
  margin-right: 0; /* Elimina el margen en el último elemento */
}

</style>