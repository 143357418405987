<template>
  <div class="content">

<!-- Modal para editar -->
<b-modal v-model="modal" title="Título del Modal" @hide="cerrarModal">
   <template #modal-header>
     <h3 class="modal-title"><b>{{ tituloModal }}</b></h3>
     <b-button @click="cerrarModal()" variant="close"></b-button>
   </template>

   <div class="modal-body">
   
    <!-- Selector tipo cliente -->

    <div>
        <b-form-group label="Seleccione: " label-for="codigo_tipo_cliente">
          <b-form-select v-model="cliente.codigo_tipo_cliente" class="form-control custom-select col-6">
              <option v-for="tipo_cliente in tipo_cliente_select" :key="tipo_cliente.codigo_tipo_cliente" :value="tipo_cliente.codigo_tipo_cliente">{{ tipo_cliente.nombre_tipo_cliente }}</option>
          </b-form-select> 
        </b-form-group>
    </div>

    <!-- end selector tipo cliente -->


    <!-- formulario para Persona Juridica codigo 1-->
    <div v-if="cliente.codigo_tipo_cliente == 1">

      <div>
        <label for="razon_social">Razón social</label>
        <input v-model="cliente.razon_social" type="text" class="form-control" id="razon_social" placeholder="Razón social">
      </div>

    </div>

    <div v-if="cliente.codigo_tipo_cliente == 1 || cliente.codigo_tipo_cliente == 2">
      <div>
        <label for="ruc">Ruc</label>
        <input v-model="cliente.ruc" type="text" class="form-control" id="ruc" placeholder="Ruc">
      </div>
    </div>
    <!-- end formulario-->



    <!-- formulario para Personal natural con negocio codigo 2 -->
    <div v-if="cliente.codigo_tipo_cliente == 2 || cliente.codigo_tipo_cliente == 3">
     

      <div>
       <label for="nombre">Nombre</label>
       <input v-model="cliente.nombre" type="text" class="form-control" id="nombre" placeholder="Nombre">
     </div>

     <div>
       <label for="apellido_paterno">Apellido paterno</label>
       <input v-model="cliente.apellido_paterno" type="text" class="form-control" id="apellido_paterno" placeholder="Apellido Paterno">
     </div>

     <div>
       <label for="apellido_materno">Apellido materno</label>
       <input v-model="cliente.apellido_materno" type="text" class="form-control" id="apellido_materno" placeholder="Apellido Materno">
     </div>


    </div>
    <!-- end formulario -->


    <!-- formulario para personal natural sin negocio codigo 3 -->
    <div v-if="cliente.codigo_tipo_cliente == 3">

        <div>
        <label for="tipo_documento">Tipo de documento</label>
        <b-form-select name="codigo_tipo_documento" id="codigo_tipo_documento" v-model="cliente.codigo_tipo_documento" :options="options_documento" required class="form-control"></b-form-select>
      </div>

      <div>
        <label for="numero_documento">Número Documento</label>
        <input v-model="cliente.numero_documento" type="text" class="form-control" id="numero_documento" placeholder="Número Documento">
      </div>

    </div>
    <!-- end formulario  -->

     <div>
          <label for="email">Email</label>
          <input v-model="cliente.email" type="text" class="form-control" id="email">
     </div>

     <div>
       <label for="celular">Celular</label>
       <input v-model="cliente.celular" type="text" class="form-control" id="celular" placeholder="Celular">
     </div>

     <div>
       <label for="telefono">Telefono</label>
       <input v-model="cliente.telefono" type="text" class="form-control" id="telefono" placeholder="Telefono">
     </div>

     <div>
       <label for="direccion">Dirección</label>
       <input v-model="cliente.direccion" type="text" class="form-control" id="direccion" placeholder="Dirección">
     </div>

   </div>

   <template #modal-footer>

       <b-button @click="add_edit_cliente()" variant="success" data-mismiss="modal">Guardar</b-button>
    
   </template>
 </b-modal>
 <!-- end -->

 <div class="md-layout">
   <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
     <md-card>
      <br>
      <div class="d-flex">
        <div class="lista-usuario-input" style="margin-right: 15px;">
           <input type="text" v-model="search" placeholder="Buscar ..." class="form-control lista-usuario-ipt">
        </div>
       <div style="margin-right: 15px;">
         <label for="estado">Estado: </label>
       </div>
       <div style="margin-right: 15px;">
         <select v-model="estado" class="form-control">
           <option value="">Todos</option>
           <option value="1">Activado</option>
           <option value="2">Desactivado</option>
         </select>
       </div>
       <div>
         <b-button @click="modificar=false; abrirModal()" type="button" class="btn btn-primary">Agregar Cliente</b-button>
       </div>
     </div>
      <br><br>
       <md-card-header data-background-color="green">
         <h4 class="title">Lista de clientes</h4>
       </md-card-header>

       <md-table>
         <!-- Encabezado de la tabla -->
         <md-table-row>
           <md-table-head class="header-cell">#</md-table-head>
           <md-table-head class="header-cell">Tipo cliente</md-table-head>
           <md-table-head class="header-cell">Razón social</md-table-head>
           <md-table-head class="header-cell">Ruc</md-table-head>
           <md-table-head class="header-cell">Nombres completos</md-table-head>
           <md-table-head class="header-cell">Documento</md-table-head>
           <md-table-head class="header-cell">Celular</md-table-head>
           <md-table-head class="header-cell">Email</md-table-head>     
           <md-table-head class="header-cell"></md-table-head>
           <md-table-head class="header-cell accion-col">Opciones</md-table-head>
           <md-table-head class="header-cell"></md-table-head>

         </md-table-row>

         <!-- listar clientes con v-for -->

         <md-table-row v-for="(item,index) in paginatedItems" :key="index">
           <md-table-cell>{{item.codigo_cliente}}</md-table-cell>
           <md-table-cell>{{item.nombre_tipo_cliente}}</md-table-cell>
           <md-table-cell>{{item.razon_social}}</md-table-cell>
           <md-table-cell>{{item.ruc}}</md-table-cell>
           <md-table-cell>{{item.nombre}} {{item.apellido_paterno}} {{item.apellido_materno}}</md-table-cell>
           <md-table-cell>{{item.descripcion}} {{item.numero_documento}}</md-table-cell>
           <md-table-cell>{{item.celular}}</md-table-cell>
           <md-table-cell>{{item.email}}</md-table-cell>
           <md-table-cell>{{item.estado ===1 ? 'Activado': 'Desactivado'}}</md-table-cell>
           <md-table-cell> <md-button @click="modificar=true; abrirModal(item)" class="md-round md-warning">Editar</md-button></md-table-cell>
           <md-table-cell><md-button @click="activar(item.codigo_cliente)" class="md-round md-success">Activar</md-button></md-table-cell>
           <md-table-cell><md-button @click="desactivar(item.codigo_cliente)" class="md-round md-danger">Desactivar</md-button></md-table-cell>
         </md-table-row>
       </md-table>

       <div class="pagination">
         <button @click="previousPage" :disabled="currentPage === 1" >Anterior</button>
            <span>{{ currentPage }} de {{ totalPages }}</span>
         <button @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button>
       </div>

     </md-card>
   </div>
 </div>
</div>

</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';
import { BFormSelect, BButton } from 'bootstrap-vue';


export default {
  
  components: {
    BFormSelect,
    BButton
  }, 
  data() {
  return {
   
   pageSize:8,
   currentPage:1,
   clientes:[],
   tipo_cliente_select:[],
   search:'',
   cliente:{
    codigo_cliente: 0,
    codigo_tipo_cliente: 0,
    razon_social: '',
    ruc: '',
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
    codigo_tipo_documento: 0,
    numero_documento: '',
    telefono: '',
    celular: '',
    email: '',
    direccion:'',
    estado: '',
   },

   codigo_cliente: 0,
   codigo_tipo_cliente: 0,
   razon_social: '',
   ruc: '',
   nombre: '',
   apellido_paterno: '',
   apellido_materno: '',
   codigo_tipo_documento: 0,
   numero_documento: '',
   telefono: '',
   celular: '',
   email: '',
   direccion:'',
   estado: '',

   options_documento:[
      { value: '1', text: 'DNI' },
      { value: '2', text: 'PASAPORTE' },
      { value: '3', text: 'CARNET DE EXTRANJERIA'}
   ],

   tituloModal:'',
   modal:false,
   modificar:true,


    };
  },

  computed: {

   totalPages() {
    return Math.ceil(this.clientes.length / this.pageSize);
    },

    paginatedItems() {
      const searchInText = this.search.toLowerCase();

      const filteredItems = this.clientes.filter(cliente => {
        return (
          Object.values(cliente)
            .map(value => (value != null ? value.toString().toLowerCase() : '')) // Asegúrate de manejar null/undefined
            .some(value => value.includes(searchInText)) &&
          (this.estado === '' || cliente.estado.toString() === this.estado)
        );
      });

      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return filteredItems.slice(startIndex, endIndex);
    }

  },

  methods: {

   //selector tipo cliente

   async select_tipo_cliente () {
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try {
     
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/selector_tipo_cliente'; 
      
      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      });
      
      this.tipo_cliente_select = res.data.tipo_cliente_select;
      //console.log('datos del selector tipo cliente: ', this.tipo_cliente_select);
    } catch (error) {
      console.error('Error selector tipo cliente', error);
    }
   },



  //end selector tipo cliente

    async listar() {
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try {
      const params = { estado: this.estado };
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/listar_cliente'; 
      
      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      }, { params });
      
      this.clientes = res.data.clientes;
      //console.log('datos', res.data.clientes)
    } catch (error) {
      console.error('Error al listar clientes:', error);
    }
  },

  previousPage(){
    if(this.currentPage > 1){
      this.currentPage--;
    }
  }, 

  nextPage(){
   if(this.currentPage<this.totalPages){
      this.currentPage++;
   }
  },

  async activar(codigo_cliente) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/cliente/' + codigo_cliente;

  try {
    const res = await axios.put(link, {
      estado: 1 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Activado',
      text: 'Cliente activado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},

async desactivar(codigo_cliente) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/cliente/' + codigo_cliente;

  try {
    const res = await axios.put(link, {
      estado: 2 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Desactivado',
      text: 'Cliente desactivado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},

  cerrarModal(){
    this.modal=false;
    this.limpiarCampos();
  },

  limpiarCampos() {
      this.cliente = {
        codigo_cliente: 0,
        codigo_tipo_cliente: 0,
        razon_social: '',
        ruc: '',
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        codigo_tipo_documento: 0,
        numero_documento: '',
        telefono: '',
        celular: '',
        email: '',
        direccion:'',
        estado: 0,
      };
    },

   async add_edit_cliente (){
     if(this.modificar){
     
     try{

      const user = {
        codigo_tipo_cliente: this.cliente.codigo_tipo_cliente,
        razon_social: this.cliente.razon_social,
        ruc: this.cliente.ruc,
        nombre: this.cliente.nombre,
        apellido_paterno: this.cliente.apellido_paterno,
        apellido_materno: this.cliente.apellido_materno,
        codigo_tipo_documento: this.cliente.codigo_tipo_documento,
        numero_documento: this.cliente.numero_documento,
        telefono: this.cliente.telefono,
        celular: this.cliente.celular,
        email: this.cliente.email,
        direccion: this.cliente.direccion,
        
      };

      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/cliente/' + this.codigo_cliente;

      const res = await axios.put(link, user, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Actualizado correctamente',
      text: 'Se modifico el cliente correctamente',
    });

  } catch (error) {
    if (error.response && error.response.status === 500) {
     const errors = error.response.data.erros;
     let errorMessage = '';
     for (const key in errors) {
      if (errors.hasOwnProperty(key)){
        errorMessage += `${errors[key][0]}\n`;
      }
     }
     Swal.fire({
      icon: 'error',
      title: '¡Error ya existe ese cliente!',
      text: errorMessage,
     });

    } else {

      Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error,
     });


    }

  }

  this.cerrarModal();
  this.listar();

     } else {

     try{
       
      const user = {
        codigo_tipo_cliente: this.cliente.codigo_tipo_cliente,
        razon_social: this.cliente.razon_social,
        ruc: this.cliente.ruc,
        nombre: this.cliente.nombre,
        apellido_paterno: this.cliente.apellido_paterno,
        apellido_materno: this.cliente.apellido_materno,
        codigo_tipo_documento: this.cliente.codigo_tipo_documento,
        numero_documento: this.cliente.numero_documento,
        telefono: this.cliente.telefono,
        celular: this.cliente.celular,
        email: this.cliente.email,
        direccion: this.cliente.direccion,
      };


      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/agregar_cliente';

      const res = await axios.post(link, user, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Agregado correctamente',
      text: 'Se agrego el cliente correctamente',
    });

     } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data && error.response.data.message ){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
      });

      } else {
        console.error('Error en la solicitud:',error);
      }

     }

     }
     this.cerrarModal();
   },

  abrirModal(data={}){
   this.modal=true;  
   if(this.modificar){
  
    this.tituloModal="Modificar Cliente";
    this.codigo_cliente=data.codigo_cliente;
    this.cliente.codigo_tipo_cliente=data.codigo_tipo_cliente;
    this.cliente.razon_social=data.razon_social;
    this.cliente.ruc=data.ruc;
    this.cliente.nombre=data.nombre;
    this.cliente.apellido_paterno=data.apellido_paterno;
    this.cliente.apellido_materno=data.apellido_materno;
    this.cliente.codigo_tipo_documento=data.codigo_tipo_documento;
    this.cliente.numero_documento=data.numero_documento;
    this.cliente.telefono=data.telefono;    
    this.cliente.celular=data.celular;
    this.cliente.email=data.email;
    this.cliente.direccion=data.direccion;
    
   }else{
    this.codigo_cliente = 0;
    this.tituloModal="Agregar Cliente";
    this.cliente.codigo_tipo_cliente = 0;
    this.cliente.razon_social= '';
    this.cliente.ruc= '';
    this.cliente.nombre= '';
    this.cliente.apellido_paterno= '';
    this.cliente.apellido_materno= '';
    this.cliente.codigo_tipo_documento= 0;
    this.cliente.numero_documento= '';
    this.cliente.telefono= '';
    this.cliente.celular= '';
    this.cliente.email= '';
    this.cliente.direccion='';
   
   }
  },
  
  },

  created() {
  this.listar();
  this.intervalId = setInterval(this.listar, 5000); //se actualiza cada 5 segundos

  this.select_tipo_cliente();
  
},
beforeDestroy() {
  clearInterval(this.intervalId); // Limpiamos el intervalo cuando se destruye el componente
}

};
</script>

<style scoped>
.header-cell {
  color: green !important; /* Asegúrate de que se aplique el color verde */
  font-weight: bold; /* Haz que el texto sea más visible */
}

.pagination button{
  margin:0 5px;
}

.lista-usuario-input{
 display: flex;
 flex-direction: row;
 justify-content: start;
 align-items: center;
 margin-left: 20px; 
}

.lista-usuario-ipt{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  margin-left: 20px; 
  flex: 1; /* Esto permite que el input ocupe el espacio restante */
  min-width: 200px; /* Ajusta el valor según sea necesario */
}

.accion-col {
  text-align: center; /* Centra el contenido horizontalmente */
}

.d-flex {
  display: flex;
  align-items: center; /* Opcional: Alinea verticalmente los elementos en el centro */
}

.d-flex > * {
  margin-right: 10px; /* Añade espacio entre los elementos */
}

.d-flex > *:last-child {
  margin-right: 0; /* Elimina el margen en el último elemento */
}

</style>