<template>
 <div>


  <div v-if="login">
      <div class="login-page">
        <div>
          <div class="login-container">
            <div class="login-card">
              <div class="card-header text-center">
                <md-card-header>
                  <h4 class="title">Sistema ERP Gen Cold</h4>
                </md-card-header>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <label for="email">Email : </label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      v-model="email" placeholder="usuario@gmail.com"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="password">Password : </label>
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      v-model="password"
                      required
                    />
                  </div>
                  <div class="md-layout-item md-size-100 text-right">
                    <md-button @click.prevent="logeo()" class="md-raised md-success">Ingresar</md-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  <div v-if="menu">

  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
    <!-- <mobile-menu slot="content"></mobile-menu>  -->
     <!-- 
      <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Indicadores</p>
      </sidebar-link>
      -->
      <sidebar-link to="/user">
        <md-icon>person</md-icon>
        <p>Usuario</p>
      </sidebar-link>
      <sidebar-link to="/cliente">
        <md-icon>person</md-icon>
        <p>Clientes</p>
      </sidebar-link>
      <sidebar-link to="/almacenServicio">
        <md-icon>content_paste</md-icon>
        <p>Almacen y Servicio</p>
      </sidebar-link>
      <sidebar-link to="/typography">
        <md-icon>library_books</md-icon>
        <p>Proyecto</p>
      </sidebar-link>
      <sidebar-link to="/icons">
        <md-icon>bubble_chart</md-icon>
        <p>Punto de venta</p>
      </sidebar-link>
     <!-- 
      <sidebar-link to="/maps">
        <md-icon>location_on</md-icon>
        <p>Maps</p>
      </sidebar-link>
     -->
      <sidebar-link to="/notifications">
        <md-icon>notifications</md-icon>
        <p>Cotización</p>
      </sidebar-link>
     <!--  
      <sidebar-link to="/upgrade" class="active-pro">
        <md-icon>unarchive</md-icon>
        <p>Upgrade to PRO</p>
      </sidebar-link>
     -->
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <dashboard-content> </dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>

</div>


</div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
//import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";


import Swal from 'sweetalert2';
import axios from 'axios';


export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    //MobileMenu,
    FixedPlugin,
  },
  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
      menu:false,
      login:true,
      email: "",
      password: "",
    };
   
  },

  methods: {
   
    async logeo() {
  const user2 = {
    email: this.email,
    password: this.password,
  };
  
  try { 
    const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/iniciaSesion';
    const res = await axios.post(link, user2);
    
    localStorage.setItem('token', res.data.token);
    localStorage.setItem('menuVisible', true); // Almacena que el menú debe estar visible

    this.login = false;
    this.menu = true;

    Swal.fire({
      icon: 'success',
      title: 'Inicio de sesión exitoso',
      text: 'Bienvenido al sistema.',
    });
    
  } catch (error) {
    if (error.response && error.response.status === 400 && error.response.data && error.response.data.message) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
      });
    } else {
      console.error('Error en la solicitud:', error);
    }
    console.error('Error en la solicitud:', error);
  }
}


  },

  created() {
  const token = localStorage.getItem('token');
  const menuVisible = localStorage.getItem('menuVisible') === 'true';
  
  if (token && menuVisible) {
    this.login = false;
    this.menu = true;
  } else {
    this.login = true;
    this.menu = false;
  }
}


};
</script>


<style scoped>
/* Asegúrate que HTML y body ocupan el 100% del viewport */
html,
body {
  height: 100%;
  margin: 0;
}
body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page {
  display: flex;
  justify-content: center; /* Centrado horizontal */
  align-items: center; /* Centrado vertical */
  width: 100vw; /* Ocupa todo el ancho de la pantalla */
  height: 100vh; /* Ocupa todo el alto de la pantalla */
  background: url("https://desarrolladorhansluyo.com/sistemas/cliente_gen_cold/contenido_multimedia/fondo-login.png")
    no-repeat center center/cover;
  position: relative;
}


.login-container {
  z-index: 2;
  max-width: 400px;
  width: 90%; /* Ajusta el ancho a un 90% para pantallas pequeñas */
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.7);
}
.login-card {
  padding: 30px;
  background: rgba(50, 50, 50, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}
.card-header {
  color: #00ff00;
}
.card-title {
  font-size: 24px;
  font-weight: bold;
  color: #00ff00;
}
.form-group label {
  color: #ccc;
}
.form-control {
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-bottom: 2px solid #00ff00;
  color: #fff;
  padding: 10px;
  margin-bottom: 20px;
  transition: border-color 0.3s;
}
.form-control:focus {
  border-bottom: 2px solid #00ff99;
  outline: none;
  background: rgba(0, 0, 0, 0.6); /* Mantén el fondo oscuro al escribir */
  color: #fff; /* Mantén el texto blanco */
}
.btn-login {
  background: linear-gradient(45deg, #00ff00, #00ff99);
  color: #000;
  font-weight: bold;
  padding: 12px;
  border-radius: 25px;
  transition: background 0.3s;
}
.btn-login:hover {
  background: linear-gradient(45deg, #00ff99, #00ff00);
}
/* Estilos responsivos */
@media (max-width: 600px) {
  .login-card {
    padding: 20px; /* Reduce padding en dispositivos pequeños */
  }
  .form-control {
    padding: 8px; /* Ajusta el tamaño del campo de entrada */
  }
  .btn-login {
    padding: 10px; /* Ajusta el tamaño del botón */
  }
  .card-title {
    font-size: 20px; /* Ajusta el tamaño del texto */
  }
}
</style>