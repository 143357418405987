<template>
  <md-toolbar md-elevation="0" class="md-transparent">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <!-- 
          <div class="md-autocomplete">
            <md-autocomplete
              class="search"
              v-model="selectedEmployee"
              :md-options="employees"
            >
              <label>Search ...</label>
            </md-autocomplete>
          </div>
           -->
          <md-list>
            <!-- 
            <md-list-item href="#/">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">Dashboard</p>
            </md-list-item>
            -->
            <!-- <md-list-item href="#/notifications" class="dropdown">
              <drop-down>
                <a slot="title" class="dropdown-toggle" data-toggle="dropdown">
                  <i class="material-icons">notifications</i>
                  <span class="notification">5</span>
                  <p class="hidden-lg hidden-md">Notifications</p>
                </a>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li><a href="#">Mike John responded to your email</a></li>
                  <li><a href="#">You have 5 new tasks</a></li>
                  <li><a href="#">You're now friend with Andrew</a></li>
                  <li><a href="#">Another Notification</a></li>
                  <li><a href="#">Another One</a></li>
                </ul>
              </drop-down>
            </md-list-item> -->

            <li class="md-list-item">
              <a
                href="#/notifications"
                class="md-list-item-router md-list-item-container md-button-clean dropdown"
              >
                <div class="md-list-item-content">
                  <drop-down>
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <span class="notification">5</span>
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Mike John responded to your email</a></li>
                      <li><a href="#">You have 5 new tasks</a></li>
                      <li><a href="#">You're now friend with Andrew</a></li>
                      <li><a href="#">Another Notification</a></li>
                      <li><a href="#">Another One</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>

            <md-list-item href="#/user">
              <i class="material-icons" title="Configurar mi perfil" @click="modificar=true; abrirModal()">person</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item>

         
            <md-list-item >
              <md-button class="md-round md-success" @click.prevent="cerrarSesion()" >Cerrar Sesión</md-button>
              <!-- <p class="hidden-lg hidden-md">Profile</p> -->
            </md-list-item>
           
            
         
          </md-list>

           <!-- Modal para editar -->
            
            <b-modal v-model="modal2" title="Título del Modal" @hide="cerrarModal">
                <template #modal-header>
                  <h3 class="modal-title"><b>{{ tituloModal }}</b></h3>
                  <b-button @click="cerrarModal()" variant="close"></b-button>
                </template>

                <div class="modal-body">

                  <input v-model="usuario.id" type="hidden" id="id">

                  <div>
                    <label for="nombre">Nombre</label>
                    <input v-model="usuario.nombre" type="text" class="form-control" id="nombre" placeholder="Nombre">
                  </div>

                  <div>
                    <label for="apellido_paterno">Apellido paterno</label>
                    <input v-model="usuario.apellido_paterno" type="text" class="form-control" id="apellido_paterno" placeholder="Apellido Paterno">
                  </div>

                  <div>
                    <label for="apellido_materno">Apellido materno</label>
                    <input v-model="usuario.apellido_materno" type="text" class="form-control" id="apellido_materno" placeholder="Apellido Materno">
                  </div>

                  <div>
                    <label for="tipo_documento">Tipo de documento</label>
                    <b-form-select name="codigo_tipo_documento" id="codigo_tipo_documento" v-model="usuario.codigo_tipo_documento" :options="options_documento" required class="form-control"></b-form-select>
                  </div>

                  <div>
                    <label for="numero_documento">Número Documento</label>
                    <input v-model="usuario.numero_documento" type="text" class="form-control" id="numero_documento" placeholder="Número Documento">
                  </div>

                  <div>
                    <label for="tipo_usuario">Tipo de Usuario</label>
                    <b-form-input name="codigo_tipo_usuario" id="codigo_tipo_usuario" :value="tipo_usuario" :readonly="true" class="form-control"></b-form-input>
                  </div>
                  
                  <div>
                    <label for="email">Usuario</label>
                    <input v-model="usuario.email" type="text" class="form-control" id="email" :readonly="true">
                  </div>

                  <div>
                    <input type="hidden" id="password" name="password" v-model="usuario.password">
                    <b-form-input name="password2" id="password2" type="password" placeholder="***********" v-model="usuario.password2" required></b-form-input>
                  </div>
                </div>

                <template #modal-footer>

                    <b-button @click="edit_usuario()" variant="success" data-mismiss="modal">Guardar</b-button>
                
                </template>
              </b-modal>
              
              <!-- end -->

        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import axios from 'axios';

import Swal from 'sweetalert2';
import { BFormSelect, BFormInput, BButton } from 'bootstrap-vue';


export default {

  components: {
    BFormSelect,
    BFormInput,
    BButton
  }, 


  data() {
    return {

      selectedEmployee: null,
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],

   usuario:{
     id: '',
     nombre: '',
     apellido_paterno: '',
     apellido_materno: '',
     codigo_tipo_documento: '',
     numero_documento: '',
     codigo_tipo_usuario: '',
     email: '',
     email2: '',
     password: '',
     password2: '',
   },

   options_documento:[
      { value: '1', text: 'DNI' },
      { value: '2', text: 'PASAPORTE' },
      { value: '3', text: 'CARNET DE EXTRANJERIA'}
   ],

   tituloModal:'',
   modal2:false,
   modificar:true,
  };
  },

  computed: {
    tipo_usuario(){
      if(this.usuario.codigo_tipo_usuario === 1){
          return 'ADMINISTRADOR';
        } else {
          return 'VENDEDOR';
      }
    }
  },

  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },

    async cerrarSesion() {
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/logout';

  try {
    
    await axios.post(link, {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    localStorage.removeItem('token');
    localStorage.setItem('menuVisible', false); // Actualiza para ocultar el menú

    // Redirigir a una ruta temporal antes de ir a la raíz
    await this.$router.push({ path: '/temp' }); // Asegúrate de tener una ruta '/temp'
    this.$router.push({ path: '/' });

  } catch (error) {
    console.error('Error al cerrar sesión:', error);
  }
}, 

cerrarModal(){
    this.modal2=false;
    this.limpiarCampos();
  },

  limpiarCampos() {
      this.usuario = {
        id: '',
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        codigo_tipo_documento: '',
        numero_documento: '',
        codigo_tipo_usuario: '',
        email: '',
        password: '',
        password2: '',
      };
    },



   async abrirModal(){
   this.modal2=true;  
   if(this.modificar){

    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }
    const params = { estado: this.estado };

      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/obtenerDatosSesion'; 
      
      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      }, { params });
      
      
    this.tituloModal="Modificar mi perfil";
    this.usuario.id=res.data.id,
    this.usuario.nombre=res.data.nombre;
    this.usuario.apellido_paterno=res.data.apellido_paterno;
    this.usuario.apellido_materno=res.data.apellido_materno;
    this.usuario.codigo_tipo_documento=res.data.codigo_tipo_documento;
    this.usuario.numero_documento=res.data.numero_documento;
    this.usuario.codigo_tipo_usuario=res.data.codigo_tipo_usuario;
    this.usuario.email=res.data.email;
    this.usuario.password=res.data.password;
     }
 },
  

 async edit_usuario (){
     if(this.modificar){
     
     try{

      const user = {
        nombre: this.usuario.nombre,
        apellido_paterno: this.usuario.apellido_paterno,
        apellido_materno: this.usuario.apellido_materno,
        codigo_tipo_documento: this.usuario.codigo_tipo_documento,
        numero_documento: this.usuario.numero_documento,
        codigo_tipo_usuario: this.usuario.codigo_tipo_usuario,
        password: this.usuario.password,
        password2: this.usuario.password2,
      };

      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/usuario/' + this.usuario.id;

      const res = await axios.put(link, user, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Actualizado correctamente',
      text: 'Reiniciando Sistema ....',
    });

  } catch (error) {
    if (error.response && error.response.status === 500) {
     const errors = error.response.data.erros;
     let errorMessage = '';
     for (const key in errors) {
      if (errors.hasOwnProperty(key)){
        errorMessage += `${errors[key][0]}\n`;
      }
     }
     Swal.fire({
      icon: 'error',
      title: '¡Error ya existe ese número de documento!',
      text: errorMessage,
     });

    } else {

      Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error,
     });


    }

  }

  this.cerrarModal();
  this.cerrarSesion();
     } 
     this.cerrarModal();
   },

  },
};
</script>

<style lang="css"></style>
