<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item">
        <div v-if="fmr_menu">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Almacen y Servicio</h4>
            <!-- 
            <p class="category">
              Almacen principal <i class="fa fa-heart heart"></i>
            </p>
            -->
          </md-card-header>
          <md-card-content>
            <div class="md-layout">

              <div class="md-layout-item md-size-100">
                <div class="places-buttons text-center">
                  <h5 class="text-center">
                    Menu
                    <p class="category">Seleccione las opciones</p>
                  </h5>
                  <md-button
                    class="md-primary"
                    @click.prevent="btn_estado_producto()"
                    >Sección Estado producto</md-button
                  >
                  <md-button
                    class="md-primary"
                    @click.prevent="btn_categoria_producto()"
                    >Sección Categoria producto</md-button
                  >
                  <md-button
                    class="md-primary"
                    @click.prevent="btn_marca()"
                    >Sección Marcas</md-button
                  >
                  
                  <md-button
                    class="md-primary"
                    @click.prevent="btn_servicio()"
                    >Sección Servicios</md-button
                  >
                  
                  <md-button
                    class="md-primary"
                     @click.prevent="btn_contenedor()"
                    >Sección productos</md-button
                  >
                  <!-- 
                  <md-button
                    class="md-primary"
                    @click="notifyVue('bottom', 'right')"
                    >Bottom Right</md-button
                  >
                  -->
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
       </div>
       <div v-if="frm_estado_producto"> <!-- FORMULARIO DE Estado producto -->

        <!-- modal  -->
        <b-modal v-model="modal_estado_producto" title="Título del Modal" @hide="cerrarModal_estado_producto">
          <template #modal-header>
            <h3 class="modal-title"><b>{{ tituloModal_estado_producto }}</b></h3>
            <b-button @click="cerrarModal_estado_producto()" variant="close"></b-button>
          </template>

          <div class="modal-body">
            <div>
              <label for="nombre">Nombre del estado</label>
              <input v-model="estado_producto.nombre_estado_producto" type="text" class="form-control" id="nombre_estado_producto" placeholder="Nombre del estado">
            </div>
          </div>

          <template #modal-footer>

              <b-button @click="add_edit_estado_producto()" variant="success" data-mismiss="modal">Guardar</b-button>
          
          </template>
       </b-modal>

       <!-- Formulario de listar estado productos -->
       <div class="md-layout">
          <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
            <br>
            <div class="d-flex">
            <div class="lista-usuario-input" style="margin-right: 15px;">
              <md-button
                    class="md-primary"
                    @click.prevent="salir_general()"
                    >Salir</md-button
                  >
              </div>
              <div class="lista-usuario-input" style="margin-right: 15px;">
                  <input type="text" v-model="search_estado_producto" placeholder="Buscar ..." class="form-control lista-usuario-ipt">
              </div>
              <div style="margin-right: 15px;">
                <label for="estado">Estado: </label>
              </div>
              <div style="margin-right: 15px;">
                <select v-model="estado_prod" class="form-control">
                  <option value="">Todos</option>
                  <option value="1">Activado</option>
                  <option value="2">Desactivado</option>
                </select>
              </div>
              <div>
                <b-button @click="modificar_estado_producto=false; abrirModal_estado_producto()" type="button" class="btn btn-primary">Agregar estado producto</b-button>
              </div>
            </div>
            <br><br>
              <md-card-header data-background-color="green">
                <h4 class="title">Lista estados de productos</h4>
              </md-card-header>

              <md-table>
                <!-- Encabezado de la tabla -->
                <md-table-row>
                  <md-table-head class="header-cell">#</md-table-head>
                  <md-table-head class="header-cell">Nombre estado de producto</md-table-head>
                  <md-table-head class="header-cell">Estado</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>
                  <md-table-head class="header-cell accion-col">Opciones</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>

                </md-table-row>

                <!-- listar usuarios con v-for -->

                <md-table-row v-for="(item_estado_producto,index_estado_producto) in paginatedItems_estado_producto" :key="index_estado_producto">
                  <md-table-cell>{{item_estado_producto.codigo_estado_producto}}</md-table-cell>
                  <md-table-cell>{{item_estado_producto.nombre_estado_producto}}</md-table-cell>
                  <md-table-cell>{{item_estado_producto.estado ===1 ? 'Activado': 'Desactivado'}}</md-table-cell>
                  <md-table-cell> <md-button @click="modificar_estado_producto=true; abrirModal_estado_producto(item_estado_producto)" class="md-round md-warning">Editar</md-button></md-table-cell>
                  <md-table-cell><md-button @click="activar_estado_producto(item_estado_producto.codigo_estado_producto)" class="md-round md-success">Activar</md-button></md-table-cell>
                  <md-table-cell><md-button @click="desactivar_estado_producto(item_estado_producto.codigo_estado_producto)" class="md-round md-danger">Desactivar</md-button></md-table-cell>
                </md-table-row>
              </md-table>

              <div class="pagination">
                <button @click="previousPage_estado_producto" :disabled="currentPage_estado_producto === 1" >Anterior</button>
                  <span>{{ currentPage_estado_producto}} de {{ totalPages_estado_producto }}</span>
                <button @click="nextPage_estado_producto" :disabled="currentPage_estado_producto === totalPages_estado_producto">Siguiente</button>
              </div>

            </md-card>
         

      </div>
    </div>
       <!-- end formulario listar estado productos-->

       </div>

       <div v-if="frm_categoria_producto"> <!-- FORMULARIO Categoria producto -->

      <!-- Modal categoria producto -->
        
      <b-modal v-model="modal_categoria_producto" title="Título del Modal" @hide="cerrarModal_categoria_producto">
          <template #modal-header>
            <h3 class="modal-title"><b>{{ tituloModal_categoria_producto }}</b></h3>
            <b-button @click="cerrarModal_categoria_producto()" variant="close"></b-button>
          </template>

          <div class="modal-body">
            <div>
              <label for="descripcion_categoria_producto">Nombre de la categoria producto</label>
              <input v-model="categoria_producto.descripcion_categoria_producto" type="text" class="form-control" id="descripcion_categoria_producto" placeholder="Nombre de la categoria del producto">
            </div>
          </div>

          <template #modal-footer>

              <b-button @click="add_edit_categoria_producto()" variant="success" data-mismiss="modal">Guardar</b-button>
          
          </template>
       </b-modal>  

      <!-- end modal -->


          <!-- Formulario de listar categoria productos -->
          <div class="md-layout">
          <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
            <br>
            <div class="d-flex">
            <div class="lista-usuario-input" style="margin-right: 15px;">
              <md-button
                    class="md-primary"
                    @click.prevent="salir_general()"
                    >Salir</md-button
                  >
              </div>
              <div class="lista-usuario-input" style="margin-right: 15px;">
                  <input type="text" v-model="search_categoria_producto" placeholder="Buscar ..." class="form-control lista-usuario-ipt">
              </div>
              <div style="margin-right: 15px;">
                <label for="estado">Estado: </label>
              </div>
              <div style="margin-right: 15px;">
                <select v-model="estado_cate" class="form-control">
                  <option value="">Todos</option>
                  <option value="1">Activado</option>
                  <option value="2">Desactivado</option>
                </select>
              </div>
              <div>
                <b-button @click="modificar_categoria_producto=false; abrirModal_categoria_producto()" type="button" class="btn btn-primary">Agregar categoria producto</b-button>
              </div>
            </div>
            <br><br>
              <md-card-header data-background-color="green">
                <h4 class="title">Lista categoria de productos</h4>
              </md-card-header>

              <md-table>
                <!-- Encabezado de la tabla -->
                <md-table-row>
                  <md-table-head class="header-cell">#</md-table-head>
                  <md-table-head class="header-cell">Nombre categoria de producto</md-table-head>
                  <md-table-head class="header-cell">Estado</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>
                  <md-table-head class="header-cell accion-col">Opciones</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>

                </md-table-row>

                <!-- listar usuarios con v-for -->

                <md-table-row v-for="(item_categoria_producto,index_categoria_producto) in paginatedItems_categoria_producto" :key="index_categoria_producto">
                  <md-table-cell>{{item_categoria_producto.codigo_categoria_producto}}</md-table-cell>
                  <md-table-cell>{{item_categoria_producto.descripcion_categoria_producto}}</md-table-cell>
                  <md-table-cell>{{item_categoria_producto.estado ===1 ? 'Activado': 'Desactivado'}}</md-table-cell>
                  <md-table-cell> <md-button @click="modificar_categoria_producto=true; abrirModal_categoria_producto(item_categoria_producto)" class="md-round md-warning">Editar</md-button></md-table-cell>
                  <md-table-cell><md-button @click="activar_categoria_producto(item_categoria_producto.codigo_categoria_producto)" class="md-round md-success">Activar</md-button></md-table-cell>
                  <md-table-cell><md-button @click="desactivar_categoria_producto(item_categoria_producto.codigo_categoria_producto)" class="md-round md-danger">Desactivar</md-button></md-table-cell>
                </md-table-row>
              </md-table>

              <div class="pagination">
                <button @click="previousPage_categoria_producto" :disabled="currentPage_categoria_producto === 1" >Anterior</button>
                  <span>{{ currentPage_categoria_producto}} de {{ totalPages_categoria_producto }}</span>
                <button @click="nextPage_categoria_producto" :disabled="currentPage_categoria_producto === totalPages_categoria_producto">Siguiente</button>
              </div>

            </md-card>
         

      </div>
    </div>

    <!-- end formulario listar categoria productos-->


       </div>

       <div v-if="frm_marca"> <!-- FORMULARIO MARCA -->
       
       <!-- Modal para agregar y editar en Marca -->


       <b-modal v-model="modal_marca" title="Título del Modal" @hide="cerrarModal_marca">
          <template #modal-header>
            <h3 class="modal-title"><b>{{ tituloModal_marca }}</b></h3>
            <b-button @click="cerrarModal_marca()" variant="close"></b-button>
          </template>

          <div class="modal-body">
            <div>
              <label for="descripcion_marca">Nombre de la marca</label>
              <input v-model="marca.descripcion_marca" type="text" class="form-control" id="descripcion_marca" placeholder="Nombre de la marca">
            </div>
          </div>

          <template #modal-footer>

              <b-button @click="add_edit_marca()" variant="success" data-mismiss="modal">Guardar</b-button>
          
          </template>
       </b-modal>  


       <!-- end modal marca -->


       
         <!-- Formulario de listar marca -->
         <div class="md-layout">
          <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
            <br>
            <div class="d-flex">
            <div class="lista-usuario-input" style="margin-right: 15px;">
              <md-button
                    class="md-primary"
                    @click.prevent="salir_general()"
                    >Salir</md-button
                  >
              </div>
              <div class="lista-usuario-input" style="margin-right: 15px;">
                  <input type="text" v-model="search_marca" placeholder="Buscar ..." class="form-control lista-usuario-ipt">
              </div>
              <div style="margin-right: 15px;">
                <label for="estado">Estado: </label>
              </div>
              <div style="margin-right: 15px;">
                <select v-model="estado_marc" class="form-control">
                  <option value="">Todos</option>
                  <option value="1">Activado</option>
                  <option value="2">Desactivado</option>
                </select>
              </div>
              <div>
                <b-button @click="modificar_marca=false; abrirModal_marca()" type="button" class="btn btn-primary">Agregar marca</b-button>
              </div>
            </div>
            <br><br>
              <md-card-header data-background-color="green">
                <h4 class="title">Lista marcas</h4>
              </md-card-header>

              <md-table>
                <!-- Encabezado de la tabla -->
                <md-table-row>
                  <md-table-head class="header-cell">#</md-table-head>
                  <md-table-head class="header-cell">Marca</md-table-head>
                  <md-table-head class="header-cell">Estado</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>
                  <md-table-head class="header-cell accion-col">Opciones</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>

                </md-table-row>

                <!-- listar marcas con v-for -->

                <md-table-row v-for="(item_marca,index_marca) in paginatedItems_marca" :key="index_marca">
                  <md-table-cell>{{item_marca.codigo_marca}}</md-table-cell>
                  <md-table-cell>{{item_marca.descripcion_marca}}</md-table-cell>
                  <md-table-cell>{{item_marca.estado ===1 ? 'Activado': 'Desactivado'}}</md-table-cell>
                  <md-table-cell> <md-button @click="modificar_marca=true; abrirModal_marca(item_marca)" class="md-round md-warning">Editar</md-button></md-table-cell>
                  <md-table-cell><md-button @click="activar_marca(item_marca.codigo_marca)" class="md-round md-success">Activar</md-button></md-table-cell>
                  <md-table-cell><md-button @click="desactivar_marca(item_marca.codigo_marca)" class="md-round md-danger">Desactivar</md-button></md-table-cell>
                </md-table-row>
              </md-table>

              <div class="pagination">
                <button @click="previousPage_marca" :disabled="currentPage_marca === 1" >Anterior</button>
                  <span>{{ currentPage_marca}} de {{ totalPages_marca }}</span>
                <button @click="nextPage_marca" :disabled="currentPage_marca === totalPages_marca">Siguiente</button>
              </div>

            </md-card>
         

      </div>
    </div>




       </div> 

       <div v-if="frm_servicios"> <!-- Formulario Servicios-->

       <!-- Modal servicio -->

       <b-modal v-model="modal_servicio" title="Título del Modal" @hide="cerrarModal_servicio">
          <template #modal-header>
            <h3 class="modal-title"><b>{{ tituloModal_servicio }}</b></h3>
            <b-button @click="cerrarModal_servicio()" variant="close"></b-button>
          </template>

          <div class="modal-body">
            <div>
              <label for="descripcion_servicio">Nombre del Servicio</label>
              <input v-model="servicio.descripcion_servicio" type="text" class="form-control" id="descripcion_servicio" placeholder="Nombre del Servicio">
            </div>
          </div>

          <template #modal-footer>

              <b-button @click="add_edit_servicio()" variant="success" data-mismiss="modal">Guardar</b-button>
          
          </template>
       </b-modal>  
      
       <!-- end servicio -->

       <!-- formulario servicio -->
       
       <div class="md-layout">
          <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
            <br>
            <div class="d-flex">
            <div class="lista-usuario-input" style="margin-right: 15px;">
              <md-button
                    class="md-primary"
                    @click.prevent="salir_general()"
                    >Salir</md-button
                  >
              </div>
              <div class="lista-usuario-input" style="margin-right: 15px;">
                  <input type="text" v-model="search_servicio" placeholder="Buscar ..." class="form-control lista-usuario-ipt">
              </div>
              <div style="margin-right: 15px;">
                <label for="estado">Estado: </label>
              </div>
              <div style="margin-right: 15px;">
                <select v-model="estado_serv" class="form-control">
                  <option value="">Todos</option>
                  <option value="1">Activado</option>
                  <option value="2">Desactivado</option>
                </select>
              </div>
              <div>
                <b-button @click="modificar_servicio=false; abrirModal_servicio()" type="button" class="btn btn-primary">Agregar servicio</b-button>
              </div>
            </div>
            <br><br>
              <md-card-header data-background-color="green">
                <h4 class="title">Listar servicios</h4>
              </md-card-header>

              <md-table>
                <!-- Encabezado de la tabla -->
                <md-table-row>
                  <md-table-head class="header-cell">#</md-table-head>
                  <md-table-head class="header-cell">Servicio</md-table-head>
                  <md-table-head class="header-cell">Estado</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>
                  <md-table-head class="header-cell accion-col">Opciones</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>

                </md-table-row>

                <!-- listar servicio con v-for -->

                <md-table-row v-for="(item_servicio,index_servicio) in paginatedItems_servicio" :key="index_servicio">
                  <md-table-cell>{{item_servicio.codigo_servicio}}</md-table-cell>
                  <md-table-cell>{{item_servicio.descripcion_servicio}}</md-table-cell>
                  <md-table-cell>{{item_servicio.estado ===1 ? 'Activado': 'Desactivado'}}</md-table-cell>
                  <md-table-cell> <md-button @click="modificar_servicio=true; abrirModal_servicio(item_servicio)" class="md-round md-warning">Editar</md-button></md-table-cell>
                  <md-table-cell><md-button @click="activar_servicio(item_servicio.codigo_servicio)" class="md-round md-success">Activar</md-button></md-table-cell>
                  <md-table-cell><md-button @click="desactivar_servicio(item_servicio.codigo_servicio)" class="md-round md-danger">Desactivar</md-button></md-table-cell>
                </md-table-row>
              </md-table>

              <div class="pagination">
                <button @click="previousPage_servicio" :disabled="currentPage_servicio === 1" >Anterior</button>
                  <span>{{ currentPage_servicio}} de {{ totalPages_servicio }}</span>
                <button @click="nextPage_servicio" :disabled="currentPage_servicio === totalPages_servicio">Siguiente</button>
              </div>

            </md-card>
         

      </div>
    </div>



       <!-- end servicio -->


       </div>

       <!-- form contenedores -->
       <div v-if="frm_contenedores">

       <!-- modal contenedor -->

       <b-modal v-model="modal_contenedor" title="Título del Modal" @hide="cerrarModal_contenedor">
          <template #modal-header>
            <h3 class="modal-title"><b>{{ tituloModal_contenedor }}</b></h3>
            <b-button @click="cerrarModal_contenedor()" variant="close"></b-button>
          </template>

          <div class="modal-body">
            <div>
              <label for="nombre_contenedor">Nombre del Contenedor</label>
              <input v-model="contenedor.nombre_contenedor" type="text" class="form-control" id="nombre_contenedor" placeholder="Nombre del Contenedor">
            </div>

            <!-- Selector Categoria -->

            <div>
              <b-form-group label="Categoria" label-for="categoria">
                <b-form-select v-model="contenedor.codigo_categoria_producto" class="form-control custom-select col-6">
                  <option v-for="categoria_producto in categorias_productos_select" :key="categoria_producto.codigo_categoria_producto" :value="categoria_producto.codigo_categoria_producto">{{ categoria_producto.descripcion_categoria_producto }}</option>
                </b-form-select> 
              </b-form-group>
            </div>

            <!-- end Categoria -->   
            <div v-if="agregar">

              <div>
                <label for="cantidad">Cantidad</label>
                <input v-model="contenedor.cantidad" min="0" type="number" class="form-control" id="cantidad" placeholder="Cantidad" required>
              </div>
          
              <div>
                <label for="color">Color</label>
                <input v-model="contenedor.color" type="text" class="form-control" id="color" placeholder="Color">
              </div>

            </div>

            <div style="margin-top: 15px;">
                    <b-form-group label="Foto :" label-for="foto">
                      <b-form-file @change="handleFotoChange" required placeholder="Elegir un archivo o soltarlo aquí..." drop-placeholder="Suelta el archivo aquí..."></b-form-file>
                    </b-form-group>
            </div>

            <div class="md-layout-item md-size-100">
            <md-field maxlength="5">
              <label>Descripción</label>
              <md-textarea v-model="contenedor.descripcion"></md-textarea>
            </md-field>
           </div>

           <!-- Selector marca -->

            <div>
              <b-form-group label="Marca" label-for="marca">
                <b-form-select v-model="contenedor.codigo_marca" class="form-control custom-select col-6">
                  <option v-for="marca in marcas_select" :key="marca.codigo_marca" :value="marca.codigo_marca">{{ marca.descripcion_marca }}</option>
                </b-form-select> 
              </b-form-group>
            </div>

           <!-- end selector marca -->

           
            <div>
              <label for="modelo">Modelo</label>
              <input v-model="contenedor.modelo" type="text" class="form-control" id="modelo" placeholder="Modelo">
            </div>

            <div>
              <label for="precio_unitario">precio</label>
              <input v-model="contenedor.precio_unitario" type="number" min="0" step="0.01" pattern="\d{1,6}\.\d{1,2}" class="form-control" id="precio_unitario" placeholder="precio_unitario">
            </div>
              

          </div>

          <template #modal-footer>

              <b-button @click="add_edit_contenedor()" variant="success" data-mismiss="modal">Guardar</b-button>
          
          </template>
       </b-modal>

      <!-- end modal contenedor -->

      <!-- Formulario listar contenedor -->
      
      <div class="md-layout">
          <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
            <br>
            <div class="d-flex">
            <div class="lista-usuario-input" style="margin-right: 15px;">
              <md-button
                    class="md-primary"
                    @click.prevent="salir_general()"
                    >Salir</md-button
                  >
              </div>
              <div class="lista-usuario-input" style="margin-right: 15px;">
                  <input type="text" v-model="search_contenedor" placeholder="Buscar ..." class="form-control lista-usuario-ipt">
              </div>
              <div style="margin-right: 15px;">
                <label for="estado">Estado: </label>
              </div>
              <div style="margin-right: 15px;">
                <select v-model="estado_cont" class="form-control">
                  <option value="">Todos</option>
                  <option value="1">Activado</option>
                  <option value="2">Desactivado</option>
                </select>
              </div>
              <div>
                <b-button @click="modificar_contenedor=false; abrirModal_contenedor()" type="button" class="btn btn-primary">Agregar Contenedor</b-button>
              </div>
            </div>
            <br><br>
              <md-card-header data-background-color="green">
                <h4 class="title">Listar contenedores</h4>
              </md-card-header>

              <md-table>
                <!-- Encabezado de la tabla -->
                <md-table-row>
                  <md-table-head class="header-cell">#</md-table-head>
                  <md-table-head class="header-cell">Contenedor</md-table-head>
                  <md-table-head class="header-cell">Foto</md-table-head>
                  <md-table-head class="header-cell">Marca</md-table-head>
                  <md-table-head class="header-cell">Modelo</md-table-head>
                  <md-table-head class="header-cell">Cantidad</md-table-head>
                  <md-table-head class="header-cell">Precio unitario</md-table-head>
                  <md-table-head class="header-cell">Precio total</md-table-head>
                  <md-table-head class="header-cell">Estado</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>
                  <md-table-head class="header-cell accion-col">Opciones</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>

                </md-table-row>

                <!-- listar contenedor con v-for -->

                <md-table-row v-for="(item_contenedor,index_contenedor) in paginatedItems_contenedor" :key="index_contenedor">
                  <md-table-cell>{{item_contenedor.codigo_contenedor}}</md-table-cell>
                  <md-table-cell>{{item_contenedor.nombre_contenedor}}</md-table-cell>
                  <md-table-cell><img :src="item_contenedor.foto" alt="imagen" class="imagen-tabla" /></md-table-cell>
                  <md-table-cell>{{item_contenedor.descripcion_marca}}</md-table-cell>
                  <md-table-cell>{{item_contenedor.modelo}}</md-table-cell>
                  <md-table-cell>{{item_contenedor.cantidad}}</md-table-cell> 
                  <md-table-cell>{{item_contenedor.precio_unitario}}</md-table-cell>
                  <md-table-cell>{{item_contenedor.precio_total}}</md-table-cell>
                  <md-table-cell>{{item_contenedor.estado ===1 ? 'Activado': 'Desactivado'}}</md-table-cell>
                  <md-table-cell> <md-button @click="modificar_contenedor=true; abrirModal_contenedor(item_contenedor)" title="editar">
                    <b-icon icon="pencil"></b-icon>
                  </md-button></md-table-cell>
                  <md-table-cell><md-button @click="activar_contenedor(item_contenedor.codigo_contenedor)" title="Activar">
                    <b-icon icon="toggle-on" variant="success" ></b-icon>
                  </md-button></md-table-cell>
                  <md-table-cell><md-button @click="desactivar_contenedor(item_contenedor.codigo_contenedor)" title="Desactivar">
                    <b-icon icon="toggle-off" variant="danger" ></b-icon>
                  </md-button></md-table-cell>
                  <md-table-cell><md-button @click.prevent="listar_obtener_datos_contenedor(item_contenedor.codigo_contenedor)" title="productos">
                    <b-icon icon="calculator"></b-icon>
                  </md-button></md-table-cell>
                </md-table-row>
              </md-table>

              <div class="pagination">
                <button @click="previousPage_contenedor" :disabled="currentPage_contenedor === 1" >Anterior</button>
                  <span>{{ currentPage_contenedor}} de {{ totalPages_contenedor }}</span>
                <button @click="nextPage_contenedor" :disabled="currentPage_contenedor === totalPages_contenedor">Siguiente</button>
              </div>

            </md-card>
      </div>
    </div>

  </div>
  <!-- end form contenedor -->
      <!-- end formulario listar contenedor -->



       <div v-if="frm_productos"> <!-- Formulario productos --> 

       <!-- Modal para productos -->
          
       <b-modal v-model="modal_producto" title="Título del Modal" @hide="cerrarModal_producto">
          <template #modal-header>
            <h3 class="modal-title"><b>{{ tituloModal_producto }}</b></h3>
            <b-button @click="cerrarModal_producto()" variant="close"></b-button>
          </template>

          <div class="modal-body">
            <div>
              <label for="numero_serie">Número de serie</label>
              <input v-model="producto.numero_serie" type="text" class="form-control" id="numero_serie" placeholder="Número de serie">
            </div>  

            <div>
              <label for="color">Color</label>
              <input v-model="producto.color" type="text" class="form-control" id="color" placeholder="Color">
            </div> 
          
            <div class="md-layout-item md-size-100">
            <md-field maxlength="5">
              <label>Descripción</label>
              <md-textarea v-model="producto.descripcion"></md-textarea>
            </md-field>
           </div>

           <!-- Selector estado producto -->

            <div>
              <b-form-group label="Seleccione estado: " label-for="codigo_estado_producto">
                <b-form-select v-model="producto.codigo_estado_producto" class="form-control custom-select col-6">
                  <option v-for="estado_producto in producto_estado_select" :key="estado_producto.codigo_estado_producto" :value="estado_producto.codigo_estado_producto">{{ estado_producto.nombre_estado_producto }}</option>
                </b-form-select> 
              </b-form-group>
            </div>

           <!-- end selector estado producto -->   

          </div>

          <template #modal-footer>

              <b-button @click="add_edit_producto()" variant="success" data-mismiss="modal">Guardar</b-button>
          
          </template>
       </b-modal>

       <!-- end para modal productos-->


      <!-- modal para cambiar color multiple --> 


      <b-modal v-model="modal_producto_multiple" title="Título del Modal" @hide="cerrarModal_producto_multiple">
          <template #modal-header>
            <h3 class="modal-title"><b>{{ tituloModal_producto_multiple }}</b></h3>
            <b-button @click="cerrarModal_producto_multiple()" variant="close"></b-button>
          </template>

          <div class="modal-body">
            
            <div>
              <label for="color">Color</label>
              <input v-model="producto.color" type="text" class="form-control" id="color" placeholder="Color">
            </div> 

          </div>

          <template #modal-footer>

              <b-button @click="add_edit_producto_multiple()" variant="success" data-mismiss="modal">Guardar</b-button>
          
          </template>
       </b-modal>

      <!-- end modal -->

       <!-- Listar productos -->

       <div class="md-layout">
          <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
            <br>
            <div class="d-flex">
            <div class="lista-usuario-input" style="margin-right: 15px;">
              <md-button
                    class="md-primary"
                    @click.prevent="salir_general_producto()"
                    >Salir</md-button
                  >
              </div>
              <div class="lista-usuario-input" style="margin-right: 15px;">
                  <input type="text" v-model="search_producto" placeholder="Buscar ..." class="form-control lista-usuario-ipt">
              </div>
              <div style="margin-right: 15px;">
                <label for="estado">Estado: </label>
              </div>
              <div style="margin-right: 15px;">
                <select v-model="estado_prod_" class="form-control">
                  <option value="">Todos</option>
                  <option value="1">Activado</option>
                  <option value="2">Desactivado</option>
                </select>
              </div>
              <div style="margin-right: 15px;">
                <b-button @click="modificar_producto=false; abrirModal_producto()" type="button" class="btn btn-primary">Agregar Producto</b-button>
              </div>
              <div style="margin-right: 15px;">
                <b-button @click.prevent="abrirModal_producto_multiple()" type="button" class="btn btn-primary">Modificar color</b-button>
              </div>

            </div>
              
              <div class="d-flex">
              <div class="lista-usuario-input">
              <div style="margin-right: 15px;">
                <label>Codigo contenedor: </label>
                <input v-model="contenedores_prod.codigo_contenedor" type="text" readonly class="form-control" id="codigo_contenedor" placeholder="Código contenedor">
              </div>
              <div style="margin-right: 15px;">
                <label>Nombre contenedor: </label>
                <input v-model="contenedores_prod.nombre_contenedor" type="text" readonly class="form-control" id="nombre_contenedor" placeholder="Nombre contenedor">
              </div>
              <div style="margin-right: 15px;">
                <label>Cantidad: </label>
                <input v-model="contenedores_prod.cantidad" type="text" readonly class="form-control" id="cantidad" placeholder="Cantidad">
              </div>
             
              <div style="margin-right: 15px;">
                <label>Marca: </label>
                <input v-model="contenedores_prod.descripcion_marca" type="text" readonly class="form-control" id="descripcion_marca" placeholder="Marca">
              </div>

              <div style="margin-right: 15px;">
                <label>Modelo: </label>
                <input v-model="contenedores_prod.modelo" type="text" readonly class="form-control" id="modelo" placeholder="Modelo">
              </div>
            </div>
            </div>
            <div class="d-flex">
              <div class="lista-usuario-input">
              <div style="margin-right: 15px;">
                <label>Categoria: </label>
                <input v-model="contenedores_prod.descripcion_categoria_producto" type="text" readonly class="form-control" id="descripcion_categoria_producto" placeholder="Categoria">
              </div>
              <div style="margin-right: 15px;">
                <label>Precio Unitario</label>
                <input v-model="contenedores_prod.precio_unitario" type="text" readonly class="form-control" id="precio_unitario" placeholder="Precio Unitario">
              </div>
              <div style="margin-right: 15px;">
                <label>Precio total</label>
                <input v-model="contenedores_prod.precio_total" type="text" readonly class="form-control" id="precio_total" placeholder="Precio Total">
              </div>
              
              <!-- Foto -->
               
              <div style="margin-right: 15px;">
                <img :src="contenedores_prod.foto" alt="imagen" class="imagen-tabla" />
              </div>

              <div style="margin-right: 15px;">
                <div class="md-layout-item md-size-100">
                  <md-field maxlength="5">
                    <label>Descripción</label>
                    <md-textarea v-model="contenedores_prod.descripcion"></md-textarea>
                  </md-field>
                </div>
              </div>
              
            </div>
              <!-- end foto -->
            </div>
            <br><br>
              <md-card-header data-background-color="green">
                <h4 class="title">Listar productos</h4>
              </md-card-header>
             
              <md-table>
                
                <md-table-row>
                  <md-table-head class="header-cell">#</md-table-head>
                  <md-table-head class="header-cell">Número de Serie</md-table-head>
                  <md-table-head class="header-cell">Color</md-table-head>                  
                  <md-table-head class="header-cell">Estado de producto</md-table-head>
                  <md-table-head class="header-cell">Estado</md-table-head>
                  <md-table-head class="header-cell text-center">Seleccione</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>
                  <md-table-head class="header-cell accion-col">Opciones</md-table-head>
                  <md-table-head class="header-cell"></md-table-head>

                </md-table-row>

                <md-table-row v-for="(item_producto,index_producto) in paginatedItems_producto" :key="index_producto">
                  <md-table-cell>{{item_producto.codigo_producto}}</md-table-cell>
                  <md-table-cell>{{item_producto.numero_serie}}</md-table-cell>
                  <md-table-cell>{{item_producto.color}}</md-table-cell>                  
                  <md-table-cell>{{item_producto.nombre_estado_producto}}</md-table-cell>
                  <md-table-cell>{{item_producto.estado ===1 ? 'Activado': 'Desactivado'}}</md-table-cell>
                  <md-table-cell class="text-center"><b-form-checkbox v-model="registroSeleccionadoProducto[item_producto.codigo_producto]" class="my-checkbox"></b-form-checkbox></md-table-cell>
                  <md-table-cell> <md-button @click="modificar_producto=true; abrirModal_producto(item_producto)" class="md-round md-warning">Editar</md-button></md-table-cell>
                  <md-table-cell><md-button @click="activar_producto(item_producto.codigo_producto)" class="md-round md-success">Activar</md-button></md-table-cell>
                  <md-table-cell><md-button @click="desactivar_producto(item_producto.codigo_producto)" class="md-round md-danger">Desactivar</md-button></md-table-cell>
                  
                </md-table-row>
              </md-table>

              <div class="pagination">
                <button @click="previousPage_producto" :disabled="currentPage_producto === 1" >Anterior</button>
                  <span>{{ currentPage_producto}} de {{ totalPages_producto }}</span>
                <button @click="nextPage_producto" :disabled="currentPage_producto === totalPages_producto">Siguiente</button>
              </div>
            
            </md-card>
      </div>
    </div>

       <!-- end listar productos -->

       </div> 
      

      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';
import { BButton , BFormGroup, BFormSelect, BFormFile  } from 'bootstrap-vue';


export default {
  components: {
    BButton,
    BFormGroup,
    BFormSelect,
    BFormFile 
  }, 

  data() {
    return {
      agregar: false,
      intervalIds: [],
      frm_estado_producto: false,
      frm_categoria_producto: false,
      frm_marca: false,
      frm_contenedores: false,
      frm_productos: false,
      frm_servicios: false,
      fmr_menu: true,
      //estado producto
      search_estado_producto:'',
      estado_prod: '',
      pageSize_estado_producto:8,
      currentPage_estado_producto:1,
      codigo_estado_producto: 0,
      estado_producto:{
      codigo_estado_producto: 0,
      nombre_estado_producto: '',
      },
      tituloModal_estado_producto:'',
      modal_estado_producto:false,
      modificar_estado_producto:true,
      estado_productos:[], //listar estado producto
      //end estado producto

      //listar categoria producto
      categorias_productos:[], 
      
      search_categoria_producto:'',
      estado_cate: '',
      pageSize_categoria_producto:8,
      currentPage_categoria_producto:1,
      codigo_categoria_producto: 0,
      categoria_producto:{
      codigo_categoria_producto: 0,
      descripcion_categoria_producto: '',
      },
      tituloModal_categoria_producto:'',
      modal_categoria_producto:false,
      modificar_categoria_producto:true,

      //end categoria producto

      marcas:[], //listar marcas

      search_marca:'',
      estado_marc: '',
      pageSize_marca:8,
      currentPage_marca:1,
      codigo_marca: 0,
      marca:{
      codigo_marca: 0,
      descripcion_marca: '',
      },
      tituloModal_marca:'',
      modal_marca:false,
      modificar_marca:true,

      //marcas end

      //servicios

      servicios:[],

      search_servicio:'',
      estado_serv: '',
      pageSize_servicio:8,
      currentPage_servicio:1,
      codigo_servicio: 0,
      servicio:{
      codigo_servicio: 0,
      descripcion_servicio: '',
      },
      tituloModal_servicio:'',
      modal_servicio:false,
      modificar_servicio:true,

      //end servicios

      //Contenedores
      contenedores:[],
      categorias_productos_select:[],
      marcas_select:[],

      search_contenedor:'',
      estado_cont: '',
      pageSize_contenedor:8,
      currentPage_contenedor:1,
      codigo_contenedor: 0,
      
      contenedor:{
        nombre_contenedor: '',
        codigo_categoria_producto: '',
        cantidad: 0,
        color:'',
        descripcion: '',
        codigo_marca: 0,
        modelo: '',
        precio_unitario: 0,
        foto: '',
      },

      tituloModal_contenedor:'',
      modal_contenedor:false,
      modificar_contenedor:true,

      //end contenedores

     //listar productos
      productos:[], 
      contenedores_prod:{
       codigo_contenedor:0,
       nombre_contenedor:'',
       cantidad:0,
       foto:'',
       descripcion:'',
       descripcion_marca:'',
       modelo:'',
       descripcion_categoria_producto:'',
       precio_unitario:0,
       precio_total:0,
      },
      producto_estado_select:[],

      search_producto:'',
      estado_prod_: '',
      pageSize_producto:8,
      currentPage_producto:1,
      codigo_producto: 0,


      producto:{
        numero_serie: '',
        color: '',
        descripcion: '',
        codigo_estado_producto: 0,
      },

      tituloModal_producto:'',
      modal_producto:false,
      modificar_producto:true,

     //end listar productos 
      
    //producto selectores multiples

      tituloModal_producto_multiple:'',
      modal_producto_multiple:false,
      modificar_producto_multiple:true,
      registroSeleccionadoProducto: {},
    //
    };
  },


computed: {

 //paginado y filtro de estado de producto 
totalPages_estado_producto() {
 return Math.ceil(this.estado_productos.length / this.pageSize_estado_producto);
},

paginatedItems_estado_producto() {
 const filteredItems = this.estado_productos.filter(estado_product => {
   return( 
     Object.values(estado_product).some(value => 
     value.toString().toLowerCase().includes(this.search_estado_producto.toLowerCase())
   ) && 
   (this.estado_prod === '' || estado_product.estado.toString() === this.estado_prod) 
  );
 });

 const startIndex = (this.currentPage_estado_producto - 1) * this.pageSize_estado_producto;
 const endIndex = startIndex + this.pageSize_estado_producto;
 return filteredItems.slice(startIndex, endIndex);
},

//end paginado y filtro de estado de producto



//paginado y filtro de categoria de producto 
totalPages_categoria_producto() {
 return Math.ceil(this.categorias_productos.length / this.pageSize_categoria_producto);
},

paginatedItems_categoria_producto() {
 const filteredItems = this.categorias_productos.filter(categoria_product => {
   return( 
     Object.values(categoria_product).some(value => 
     value.toString().toLowerCase().includes(this.search_categoria_producto.toLowerCase())
   ) && 
   (this.estado_cate === '' || categoria_product.estado.toString() === this.estado_cate) 
  );
 });

 const startIndex = (this.currentPage_categoria_producto - 1) * this.pageSize_categoria_producto;
 const endIndex = startIndex + this.pageSize_categoria_producto;
 return filteredItems.slice(startIndex, endIndex);
},

//end paginado y filtro de categoria de producto


//paginado y filtro marca

totalPages_marca() {
 return Math.ceil(this.marcas.length / this.pageSize_marca);
},

paginatedItems_marca() {
 const filteredItems = this.marcas.filter(marc => {
   return( 
     Object.values(marc).some(value => 
     value.toString().toLowerCase().includes(this.search_marca.toLowerCase())
   ) && 
   (this.estado_marc === '' || marc.estado.toString() === this.estado_marc) 
  );
 });

 const startIndex = (this.currentPage_marca - 1) * this.pageSize_marca;
 const endIndex = startIndex + this.pageSize_marca;
 return filteredItems.slice(startIndex, endIndex);
},


//end marca

//servicio
 

totalPages_servicio() {
 return Math.ceil(this.servicios.length / this.pageSize_servicio);
},

paginatedItems_servicio() {
 const filteredItems = this.servicios.filter(ser => {
   return( 
     Object.values(ser).some(value => 
     value.toString().toLowerCase().includes(this.search_servicio.toLowerCase())
   ) && 
   (this.estado_serv === '' || ser.estado.toString() === this.estado_serv) 
  );
 });

 const startIndex = (this.currentPage_servicio - 1) * this.pageSize_servicio;
 const endIndex = startIndex + this.pageSize_servicio;
 return filteredItems.slice(startIndex, endIndex);
},

//end servicio

//contenedor paginado y filtro


totalPages_contenedor() {
 return Math.ceil(this.contenedores.length / this.pageSize_contenedor);
},

paginatedItems_contenedor() {
 const filteredItems = this.contenedores.filter(cont => {
   return( 
     Object.values(cont).some(value => 
     value.toString().toLowerCase().includes(this.search_contenedor.toLowerCase())
   ) && 
   (this.estado_cont === '' || cont.estado.toString() === this.estado_cont) 
  );
 });

 const startIndex = (this.currentPage_contenedor - 1) * this.pageSize_contenedor;
 const endIndex = startIndex + this.pageSize_contenedor;
 return filteredItems.slice(startIndex, endIndex);
},

//end contenedor 

//productos
  
totalPages_producto() {
 return Math.ceil(this.productos.length / this.pageSize_producto);
},

paginatedItems_producto() {
  const searchInText = this.search_producto.toLowerCase();

  const filteredItems = this.productos.filter(prod => {
    return (
      Object.values(prod)
        .map(value => (value != null ? value.toString().toLowerCase() : '')) // Asegúrate de manejar null/undefined
        .some(value => value.includes(searchInText)) &&
      (this.estado_prod_ === '' || prod.estado.toString() === this.estado_prod_)
    );
  });

  const startIndex = (this.currentPage_producto - 1) * this.pageSize_producto;
  const endIndex = startIndex + this.pageSize_producto;
  return filteredItems.slice(startIndex, endIndex);
}

//end productos

},


  methods: {


   //Contenedor

    handleFotoChange(event) {
                this.contenedor.foto = event.target.files[0];
    },

    async btn_contenedor(){
      this.frm_estado_producto= false;
      this.frm_categoria_producto= false;
      this.frm_marca= false;
      this.frm_contenedores= true;
      this.frm_productos= false;
      this.frm_servicios= false;
      this.fmr_menu= false;
    },

    //listado contenedor
    async listar_contenedor() {
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try {
      const params = { estado: this.estado_cont };
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/listar_contenedor_producto'; 
      
      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      }, { params });
      
      this.contenedores = res.data.contenedores;
      //console.log('Datos de contenedores:', res.data.contenedores);
    } catch (error) {
      console.error('Error al listar contenedores:', error);
    }
  },

  //paginado listado contenedor

  previousPage_contenedor(){
    if(this.currentPage_contenedor > 1){
      this.currentPage_contenedor--;
    }
  }, 

  nextPage_contenedor(){
   if(this.currentPage_contenedor<this.totalPages_contenedor){
      this.currentPage_contenedor++;
   }
  },


  async activar_contenedor(codigo_contenedor) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/contenedor/' + codigo_contenedor;

  try {
    const res = await axios.put(link, {
      estado: 1 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_contenedor(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Activado',
      text: 'Contenedor activado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},

async desactivar_contenedor(codigo_contenedor) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/contenedor/' + codigo_contenedor;

  try {
    const res = await axios.put(link, {
      estado: 2 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_contenedor(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Desactivado',
      text: 'Contenedor desactivado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},


  //end listado contenedor
  
  
  //modal para contenedor

    cerrarModal_contenedor(){
    this.modal_contenedor=false;
    this.limpiarCampos_contenedor();
  },

  limpiarCampos_contenedor() {
      this.contenedor = {
        nombre_contenedor: '',
        codigo_categoria_producto: '',
        cantidad: '',
        descripcion: '',
        codigo_marca: '',
        modelo: '',
        precio_unitario: '',
        foto: '',
      };
    },

  abrirModal_contenedor(data={}){
   this.modal_contenedor=true;  
   if(this.modificar_contenedor){
    this.tituloModal_contenedor="Modificar Contenedor";
    this.agregar = false;
    this.codigo_contenedor=data.codigo_contenedor,
    this.contenedor.nombre_contenedor=data.nombre_contenedor;
    this.contenedor.codigo_categoria_producto = data.codigo_categoria_producto;
    this.contenedor.cantidad = data.cantidad;
    this.contenedor.descripcion = data.descripcion;
    this.contenedor.codigo_marca = data.codigo_marca;
    this.contenedor.modelo = data.modelo;
    this.contenedor.precio_unitario = data.precio_unitario;
    this.contenedor.foto = data.foto;

   }else{
    this.codigo_contenedor = 0;
    this.tituloModal_contenedor="Agregar Contenedor";
    this.agregar = true;
    this.contenedor.nombre_contenedor='';
    this.contenedor.codigo_categoria_producto = '';
    this.contenedor.cantidad = '';
    this.contenedor.color = '';
    this.contenedor.descripcion = '';
    this.contenedor.codigo_marca = '';
    this.contenedor.modelo = '';
    this.contenedor.precio_unitario = '';
    this.contenedor.foto = '';
    
   }
  },

  //modal end


 //Agregar contenedor

 async add_edit_contenedor (){
     if(this.modificar_contenedor){
     
            try{
            
              let base64Foto = null;

              // Si hay una nueva imagen, conviértela a Base64
              if (this.contenedor.foto instanceof File) {
                  base64Foto = await new Promise((resolve, reject) => {
                      const reader = new FileReader();
                      reader.onload = () => resolve(reader.result.split(',')[1]); // Base64 sin prefijo
                      reader.onerror = reject;
                      reader.readAsDataURL(this.contenedor.foto);
                  });
              }

              // Crear el payload
              const payload = {
                  nombre_contenedor: this.contenedor.nombre_contenedor,
                  codigo_categoria_producto: this.contenedor.codigo_categoria_producto,
                  descripcion: this.contenedor.descripcion,
                  codigo_marca: this.contenedor.codigo_marca,
                  modelo: this.contenedor.modelo,
                  precio_unitario: this.contenedor.precio_unitario,
                  foto: base64Foto, // Si no hay nueva foto, será null
              };

              // Configurar token y URL
              const token = localStorage.getItem('token');
              const link = `https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/contenedor/${this.codigo_contenedor}`;

              // Enviar solicitud al backend
              const res = await axios.put(link, payload, {
                  headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json',
                  },
                  withCredentials: true, // Envío de credenciales si es necesario
              });

              console.log('Datos actualizados:', res.data);


                  Swal.fire({
                  icon: 'success',
                  title: 'Actualizado correctamente',
                  text: 'Se modifico el Contenedor correctamente',
                });

          } catch (error) {
            if (error.response && error.response.status === 500) {
            const errors = error.response.data.erros;
            let errorMessage = '';
            for (const key in errors) {
              if (errors.hasOwnProperty(key)){
                errorMessage += `${errors[key][0]}\n`;
              }
            }
            Swal.fire({
              icon: 'error',
              title: '¡Ocurrio un problema!',
              text: errorMessage,
            });

            } else {

              Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error,
            });


            }

          }

          this.cerrarModal_contenedor();
          this.listar_contenedor();

     } else {

                  try{
                    
                    const formData = new FormData();
                      formData.append('nombre_contenedor', this.contenedor.nombre_contenedor);
                      formData.append('codigo_categoria_producto', this.contenedor.codigo_categoria_producto);
                      formData.append('cantidad', this.contenedor.cantidad);
                      formData.append('color', this.contenedor.color);
                      formData.append('descripcion', this.contenedor.descripcion);
                      formData.append('codigo_marca', this.contenedor.codigo_marca);
                      formData.append('modelo', this.contenedor.modelo);
                      formData.append('precio_unitario', this.contenedor.precio_unitario);
                      if (this.contenedor.foto) {
                          formData.append('foto', this.contenedor.foto);
                      }


                    const token = localStorage.getItem('token');
                    const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/agregar_contenedor';

                    const res = await axios.post(link, formData, {
                    headers: {
                      Authorization: `Bearer ${token}`, // Autenticación usando token
                    },
                    withCredentials: true, // Envío de credenciales
                  });

                    Swal.fire({
                    icon: 'success',
                    title: 'Agregado correctamente',
                    text: 'Se agrego el Contenedor correctamente',
                  });

                  } catch (error) {
                    if (error.response && error.response.status === 500 && error.response.data && error.response.data.message ){
                    Swal.fire({
                      icon: 'error',
                      title: 'Ocurrio un problema en el Sistema',
                      text: error.response.data.message,
                    });

                    } else {
                      console.error('Error en la solicitud:',error);
                    }

                  }

          }
          this.cerrarModal_contenedor();
          this.listar_contenedor();
   },

    //end contenedor

   //Selector categoria productos
   
   async selector_categoria () {
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try {
     
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/selector_categoria_producto'; 
      
      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      });
      
      this.categorias_productos_select = res.data.categorias_productos_select;

    } catch (error) {
      console.error('Error selector categoria', error);
    }
   },

   //Selector marcas

   async marcas_selector () {

    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try{ 

    const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/selector_marca'
    const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      });
      
      this.marcas_select = res.data.marcas_select;

   } catch (error) {
      console.error('Error selector marca', error);
    }


   },

    //producto

    salir_general_producto(){
      this.frm_estado_producto= false;
      this.frm_categoria_producto= false;
      this.frm_marca= false;
      this.frm_contenedores= true;
      this.frm_productos= false;
      this.frm_servicios= false;
      this.fmr_menu= false;
    },


   //obteniedo datos del contenedor 
   async listar_obtener_datos_contenedor(codigo_contenedor){

      this.frm_estado_producto= false;
      this.frm_categoria_producto= false;
      this.frm_marca= false;
      this.frm_contenedores= false;
      this.frm_productos= true;
      this.frm_servicios= false;
      this.fmr_menu= false;
    
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }
   
    try{
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/mostrar_dato_contenedor/'+codigo_contenedor; 
    
      const res = await axios.get(link, {
      headers: {
        Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
      },
      withCredentials: true, // Para enviar cookies o credenciales si es necesario
    });
    
    this.contenedores_prod.codigo_contenedor = res.data.codigo_contenedor;
    this.contenedores_prod.nombre_contenedor = res.data.nombre_contenedor;
    this.contenedores_prod.cantidad = res.data.cantidad;
    this.contenedores_prod.foto = res.data.foto;
    this.contenedores_prod.descripcion = res.data.descripcion;
    this.contenedores_prod.descripcion_marca = res.data.descripcion_marca;
    this.contenedores_prod.modelo = res.data.modelo;
    this.contenedores_prod.descripcion_categoria_producto = res.data.descripcion_categoria_producto;
    this.contenedores_prod.precio_unitario = res.data.precio_unitario;
    this.contenedores_prod.precio_total = res.data.precio_total;
    //console.log(res.data.codigo_contenedor)
    this.listar_producto(codigo_contenedor);

    } catch (error) {
      console.error('Error en obtener datos del contenedor:', error);
    }

   },

    //listado producto
    async listar_producto(codigo_contenedor) {
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try {
      const params = { estado: this.estado_prod_ };
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/listar_producto/'+codigo_contenedor; 
      
      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      }, { params });
      
      this.productos = res.data.productos;
      //console.log('productos console: ',res);
    } catch (error) {
      console.error('Error al listar productos:', error);
    }
  },

  //paginado listado producto

  previousPage_producto(){
    if(this.currentPage_producto > 1){
      this.currentPage_producto--;
    }
  }, 

  nextPage_producto(){
   if(this.currentPage_producto<this.totalPages_producto){
      this.currentPage_producto++;
   }
  },


  async activar_producto(codigo_producto) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/producto/' + codigo_producto;

  try {
    const res = await axios.put(link, {
      estado: 1 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_obtener_datos_contenedor(this.contenedores_prod.codigo_contenedor); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Activado',
      text: 'Producto Restaurado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},

async desactivar_producto(codigo_producto) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/producto/' + codigo_producto;

  try {
    const res = await axios.put(link, {
      estado: 2 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_obtener_datos_contenedor(this.contenedores_prod.codigo_contenedor); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Desactivado',
      text: 'Producto eliminado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},


  //end listado producto
  
  
  //modal para producto

    cerrarModal_producto(){
    this.modal_producto=false;
    this.limpiarCampos_producto();
  },

  limpiarCampos_producto() {
      this.producto = {
        numero_serie: '',
        color: '',
        descripcion: '',
        codigo_estado_producto: 0,
      };
    },

  abrirModal_producto(data={}){
   this.modal_producto=true;  
   if(this.modificar_producto){
    this.tituloModal_producto="Modificar Producto";
    this.codigo_producto=data.codigo_producto,
    this.producto.numero_serie=data.numero_serie;
    this.producto.color = data.color;
    this.producto.descripcion = data.descripcion;
    this.producto.codigo_estado_producto = data.codigo_estado_producto;

   }else{
    this.codigo_producto = 0;
    this.tituloModal_producto="Agregar Producto";
    this.producto.numero_serie='';
    this.producto.color = '';
    this.producto.descripcion = '';
    this.producto.codigo_estado_producto = '';

   }
  },

  //modal end


 //Agregar producto

 async add_edit_producto (){
     if(this.modificar_producto){
     
     try{

      const producto_ = {
        numero_serie: this.producto.numero_serie,
        color: this.producto.color,
        descripcion: this.producto.descripcion,
        codigo_estado_producto: this.producto.codigo_estado_producto,
      };

      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/producto/' + this.codigo_producto;

      const res = await axios.put(link, producto_ , {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Actualizado correctamente',
      text: 'Se modifico el Producto correctamente',
    });

  } catch (error) {
    if (error.response && error.response.status === 500) {
     const errors = error.response.data.erros;
     let errorMessage = '';
     for (const key in errors) {
      if (errors.hasOwnProperty(key)){
        errorMessage += `${errors[key][0]}\n`;
      }
     }
     Swal.fire({
      icon: 'error',
      title: '¡Ocurrio un problema!',
      text: errorMessage,
     });

    } else {

      Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error,
     });


    }

  }

  this.cerrarModal_producto();
  this.listar_obtener_datos_contenedor(this.contenedores_prod.codigo_contenedor);

     } else {

     try{
       
      const producto_ = {
        codigo_contenedor: this.contenedores_prod.codigo_contenedor,
        numero_serie: this.producto.numero_serie,
        color: this.producto.color,
        descripcion: this.producto.descripcion,
        codigo_estado_producto: this.producto.codigo_estado_producto,
      };


      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/agregar_producto';

      const res = await axios.post(link, producto_, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Agregado correctamente',
      text: 'Se agrego el Producto correctamente',
    });

     } catch (error) {
      if (error.response && error.response.status === 500 && error.response.data && error.response.data.message ){
      Swal.fire({
        icon: 'error',
        title: 'Ocurrio un problema en el Sistema',
        text: error.response.data.message,
      });

      } else {
        console.error('Error en la solicitud:',error);
      }

     }

     }
     this.cerrarModal_producto();
     this.listar_obtener_datos_contenedor(this.contenedores_prod.codigo_contenedor);
   },

    //end producto

   //Selector estado producto
   
   async selector_estado_producto () {
     const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try {
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/selector_estado_productos';

      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      });
      
      this.producto_estado_select = res.data.producto_estado_select;
   
    } catch (error) {
      console.error('Error selector estado producto:', error);
    }

   },

   //end producto

  //producto multiple

  cerrarModal_producto_multiple(){
    this.modal_producto_multiple=false;
    this.limpiarCampos_producto_multiple();
  },

  limpiarCampos_producto_multiple() {
      this.producto = {
        color: '',
      };
    },

  abrirModal_producto_multiple(){
   this.modal_producto_multiple=true;  
    this.tituloModal_producto_multiple="Cambiar color";
    this.producto.color = '';
  },


  async add_edit_producto_multiple (){
      
      try { 
      const registrosSeleccionados = Object.keys(this.registroSeleccionadoProducto)
            .filter(codigo_producto => this.registroSeleccionadoProducto[codigo_producto])
            .map(codigo_producto => parseInt(codigo_producto));



            const producto_ = {
              color: this.producto.color,
            };


        // Itera sobre los registros seleccionados y envía una solicitud PUT para cada uno
        await Promise.all(registrosSeleccionados.map(async codigo_producto => {

          const token = localStorage.getItem('token');
          const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/producto/' + codigo_producto;

          const res = await axios.put(link, producto_ , {
          headers: {
            Authorization: `Bearer ${token}`, // Autenticación usando token
          },
          withCredentials: true, // Envío de credenciales
         });


        }));
        



        this.listar_obtener_datos_contenedor(this.contenedores_prod.codigo_contenedor);
        Swal.fire({
              icon: 'success',
              title: 'Cambio de costo Actualizado',
              text: 'Se cambio el color de los registros seleccionados',
              });

      } catch (error) {
              if (error.response && error.response.status === 400 && error.response.data && error.response.data.message) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message,
            });

              } else {
                  console.error('Error en la solicitud:', error);
              }
          }

     this.cerrarModal_producto_multiple();
    },


   //end modal producto multiple


    salir_general(){
      this.frm_estado_producto= false;
      this.frm_categoria_producto= false;
      this.frm_marca= false;
      this.frm_contenedores= false;
      this.frm_productos= false;
      this.frm_servicios= false;
      this.fmr_menu= true;
    },


    //Estado producto
    
    async btn_estado_producto(){
      this.frm_estado_producto= true;
      this.frm_categoria_producto= false;
      this.frm_marca= false;
      this.frm_contenedores= false;
      this.frm_productos= false;
      this.frm_servicios= false;
      this.fmr_menu= false;
    },

    //listado estado producto
    async listar_estado_producto() {
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try {
      const params = { estado: this.estado_prod };
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/estado_productos'; 
      
      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      }, { params });
      
      this.estado_productos = res.data.estado_productos;
      
    } catch (error) {
      console.error('Error al listar estado de productos:', error);
    }
  },

  //paginado listado estado producto

  previousPage_estado_producto(){
    if(this.currentPage_estado_producto > 1){
      this.currentPage_estado_producto--;
    }
  }, 

  nextPage_estado_producto(){
   if(this.currentPage_estado_producto<this.totalPages_estado_producto){
      this.currentPage_estado_producto++;
   }
  },


  async activar_estado_producto(codigo_estado_producto) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/estado_producto/' + codigo_estado_producto;

  try {
    const res = await axios.put(link, {
      estado: 1 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_estado_producto(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Activado',
      text: 'Estado producto activado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},

async desactivar_estado_producto(codigo_estado_producto) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/estado_producto/' + codigo_estado_producto;

  try {
    const res = await axios.put(link, {
      estado: 2 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_estado_producto(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Desactivado',
      text: 'Estado producto desactivado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},


  //end listado estado producto
  
  
  //modal para estado producto

    cerrarModal_estado_producto(){
    this.modal_estado_producto=false;
    this.limpiarCampos_estado_producto();
  },

  limpiarCampos_estado_producto() {
      this.estado_producto = {
        nombre_estado_producto: '',
      };
    },

  abrirModal_estado_producto(data={}){
   this.modal_estado_producto=true;  
   if(this.modificar_estado_producto){
    this.tituloModal_estado_producto="Modificar Estado producto";
    this.codigo_estado_producto=data.codigo_estado_producto,
    this.estado_producto.nombre_estado_producto=data.nombre_estado_producto;
   }else{
    this.codigo_estado_producto = 0;
    this.tituloModal_estado_producto="Agregar Estado producto";
    this.estado_producto.nombre_estado_producto='';
  
   }
  },

  //modal end


 //Agregar estado producto

 async add_edit_estado_producto (){
     if(this.modificar_estado_producto){
     
     try{

      const estado_productos = {
        nombre_estado_producto: this.estado_producto.nombre_estado_producto,
      };

      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/estado_producto/' + this.codigo_estado_producto;

      const res = await axios.put(link, estado_productos, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Actualizado correctamente',
      text: 'Se modifico el estado de producto correctamente',
    });

  } catch (error) {
    if (error.response && error.response.status === 500) {
     const errors = error.response.data.erros;
     let errorMessage = '';
     for (const key in errors) {
      if (errors.hasOwnProperty(key)){
        errorMessage += `${errors[key][0]}\n`;
      }
     }
     Swal.fire({
      icon: 'error',
      title: '¡Error ya existe ese estado de producto!',
      text: errorMessage,
     });

    } else {

      Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error,
     });


    }

  }

  this.cerrarModal_estado_producto();
  this.listar_estado_producto();

     } else {

     try{
       
      const estado_productos = {
        nombre_estado_producto: this.estado_producto.nombre_estado_producto,
      };


      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/agregar_estado_producto';

      const res = await axios.post(link, estado_productos, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Agregado correctamente',
      text: 'Se agrego el estado producto correctamente',
    });

     } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data && error.response.data.message ){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
      });

      } else {
        console.error('Error en la solicitud:',error);
      }

     }

     }
     this.cerrarModal_estado_producto();
     this.listar_estado_producto();
   },


  //end estado producto


  //Categoria producto

  async btn_categoria_producto(){
      this.frm_estado_producto= false;
      this.frm_categoria_producto= true;
      this.frm_marca= false;
      this.frm_contenedores= false;
      this.frm_productos= false;
      this.frm_servicios= false;
      this.fmr_menu= false;
    },

    //listado categoria producto
    async listar_categoria_producto() {
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try {
      const params = { estado: this.estado_cate };
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/listar_categoria_producto'; 
      
      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      }, { params });
      
      this.categorias_productos = res.data.categorias_productos;
      
    } catch (error) {
      console.error('Error al listar categoria de productos:', error);
    }
  },

  //paginado listado categoria producto

  previousPage_categoria_producto(){
    if(this.currentPage_categoria_producto > 1){
      this.currentPage_categoria_producto--;
    }
  }, 

  nextPage_categoria_producto(){
   if(this.currentPage_categoria_producto<this.totalPages_categoria_producto){
      this.currentPage_categoria_producto++;
   }
  },


  async activar_categoria_producto(codigo_categoria_producto) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/categoria_producto/' + codigo_categoria_producto;

  try {
    const res = await axios.put(link, {
      estado: 1 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_categoria_producto(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Activado',
      text: 'Categoria producto activado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},

async desactivar_categoria_producto(codigo_categoria_producto) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/categoria_producto/' + codigo_categoria_producto;

  try {
    const res = await axios.put(link, {
      estado: 2 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_categoria_producto(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Desactivado',
      text: 'Categoria producto desactivado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},


  //end listado categoria producto
  
  
  //modal para categoria producto

    cerrarModal_categoria_producto(){
    this.modal_categoria_producto=false;
    this.limpiarCampos_categoria_producto();
  },

  limpiarCampos_categoria_producto() {
      this.categoria_producto = {
        descripcion_categoria_producto: '',
      };
    },

  abrirModal_categoria_producto(data={}){
   this.modal_categoria_producto=true;  
   if(this.modificar_categoria_producto){
    this.tituloModal_categoria_producto="Modificar Categoria producto";
    this.codigo_categoria_producto=data.codigo_categoria_producto,
    this.categoria_producto.descripcion_categoria_producto=data.descripcion_categoria_producto;
   }else{
    this.codigo_categoria_producto = 0;
    this.tituloModal_categoria_producto="Agregar Categoria producto";
    this.categoria_producto.descripcion_categoria_producto='';
  
   }
  },

  //modal end


 //Agregar categoria producto

 async add_edit_categoria_producto (){
     if(this.modificar_categoria_producto){
     
     try{

      const categoria_productos = {
        descripcion_categoria_producto: this.categoria_producto.descripcion_categoria_producto,
      };

      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/categoria_producto/' + this.codigo_categoria_producto;

      const res = await axios.put(link, categoria_productos, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Actualizado correctamente',
      text: 'Se modifico la categoria de producto correctamente',
    });

  } catch (error) {
    if (error.response && error.response.status === 500) {
     const errors = error.response.data.erros;
     let errorMessage = '';
     for (const key in errors) {
      if (errors.hasOwnProperty(key)){
        errorMessage += `${errors[key][0]}\n`;
      }
     }
     Swal.fire({
      icon: 'error',
      title: '¡Error ya existe esa Categoria de producto!',
      text: errorMessage,
     });

    } else {

      Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error,
     });


    }

  }

  this.cerrarModal_categoria_producto();
  this.listar_categoria_producto();

     } else {

     try{
       
      const categoria_productos = {
        descripcion_categoria_producto: this.categoria_producto.descripcion_categoria_producto,
      };


      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/agregar_categoria_producto';

      const res = await axios.post(link, categoria_productos, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Agregado correctamente',
      text: 'Se agrego la Categoria producto correctamente',
    });

     } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data && error.response.data.message ){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
      });

      } else {
        console.error('Error en la solicitud:',error);
      }

     }

     }
     this.cerrarModal_categoria_producto();
     this.listar_categoria_producto();
   },

  //End categoria producto

  //marca 

   
  async btn_marca(){
      this.frm_estado_producto= false;
      this.frm_categoria_producto= false;
      this.frm_marca= true;
      this.frm_contenedores= false;
      this.frm_productos= false;
      this.frm_servicios= false;
      this.fmr_menu= false;
    },

    //listado marca
    async listar_marca() {
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try {
      const params = { estado: this.estado_marc };
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/listar_marca'; 
      
      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      }, { params });
      
      this.marcas = res.data.marcas;
      
    } catch (error) {
      console.error('Error al listar marcas:', error);
    }
  },

  //paginado listado marca

  previousPage_marca(){
    if(this.currentPage_marca > 1){
      this.currentPage_marca--;
    }
  }, 

  nextPage_marca(){
   if(this.currentPage_marca<this.totalPages_marca){
      this.currentPage_marca++;
   }
  },


  async activar_marca(codigo_marca) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/marca/' + codigo_marca;

  try {
    const res = await axios.put(link, {
      estado: 1 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_marca(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Activado',
      text: 'Marca activado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},

async desactivar_marca(codigo_marca) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/marca/' + codigo_marca;

  try {
    const res = await axios.put(link, {
      estado: 2 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_marca(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Desactivado',
      text: 'Marca desactivado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},


  //end listado marca
  
  
  //modal para marca

    cerrarModal_marca(){
    this.modal_marca=false;
    this.limpiarCampos_marca();
  },

  limpiarCampos_marca() {
      this.marca = {
        descripcion_marca: '',
      };
    },

  abrirModal_marca(data={}){
   this.modal_marca=true;  
   if(this.modificar_marca){
    this.tituloModal_marca="Modificar Marca";
    this.codigo_marca=data.codigo_marca,
    this.marca.descripcion_marca=data.descripcion_marca;
   }else{
    this.codigo_marca = 0;
    this.tituloModal_marca="Agregar Marca";
    this.marca.descripcion_marca='';
  
   }
  },

  //modal end


 //Agregar marca

 async add_edit_marca (){
     if(this.modificar_marca){
     
     try{

      const marca_ = {
        descripcion_marca: this.marca.descripcion_marca,
      };

      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/marca/' + this.codigo_marca;

      const res = await axios.put(link, marca_, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Actualizado correctamente',
      text: 'Se modifico la Marca correctamente',
    });

  } catch (error) {
    if (error.response && error.response.status === 500) {
     const errors = error.response.data.erros;
     let errorMessage = '';
     for (const key in errors) {
      if (errors.hasOwnProperty(key)){
        errorMessage += `${errors[key][0]}\n`;
      }
     }
     Swal.fire({
      icon: 'error',
      title: '¡Error ya existe esa Marca!',
      text: errorMessage,
     });

    } else {

      Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error,
     });


    }

  }

  this.cerrarModal_marca();
  this.listar_marca();

     } else {

     try{
       
      const marca_ = {
        descripcion_marca: this.marca.descripcion_marca,
      };


      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/agregar_marca';

      const res = await axios.post(link, marca_, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Agregado correctamente',
      text: 'Se agrego la Marca correctamente',
    });

     } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data && error.response.data.message ){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
      });

      } else {
        console.error('Error en la solicitud:',error);
      }

     }

     }
     this.cerrarModal_marca();
     this.listar_marca();
   },


  //end marca

 //servicios


 async btn_servicio(){
      this.frm_estado_producto= false;
      this.frm_categoria_producto= false;
      this.frm_marca= false;
      this.frm_contenedores= false;
      this.frm_productos= false;
      this.frm_servicios= true;
      this.fmr_menu= false;
    },

    //listar servicio
    async listar_servicio() {
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
    
    if (!token) {
      console.log('Sesión cerrada, detener la solicitud');
      return; // Si no hay token, salimos del método y no ejecutamos la solicitud
    }

    try {
      const params = { estado: this.estado_serv };
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/listar_servicio'; 
      
      const res = await axios.get(link, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los encabezados de la solicitud
        },
        withCredentials: true, // Para enviar cookies o credenciales si es necesario
      }, { params });
      
      this.servicios = res.data.servicios;
      
    } catch (error) {
      console.error('Error al listar servicios:', error);
    }
  },

  //paginado listado servicio

  previousPage_servicio(){
    if(this.currentPage_servicio > 1){
      this.currentPage_servicio--;
    }
  }, 

  nextPage_servicio(){
   if(this.currentPage_servicio<this.totalPages_servicio){
      this.currentPage_servicio++;
   }
  },


  async activar_servicio(codigo_servicio) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/servicio/' + codigo_servicio;

  try {
    const res = await axios.put(link, {
      estado: 1 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_servicio(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Activado',
      text: 'Servicio activado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},

async desactivar_servicio(codigo_servicio) {
  const token = localStorage.getItem('token');
  const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/servicio/' + codigo_servicio;

  try {
    const res = await axios.put(link, {
      estado: 2 // Datos enviados en el cuerpo de la solicitud
    }, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

    this.listar_servicio(); // Actualiza la lista

    Swal.fire({
      icon: 'success',
      title: 'Desactivado',
      text: 'Servicio desactivado',
    });


  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
},


  //end listado servicio
  
  
  //modal para servicio

    cerrarModal_servicio(){
    this.modal_servicio=false;
    this.limpiarCampos_servicio();
  },

  limpiarCampos_servicio() {
      this.servicio = {
        descripcion_servicio: '',
      };
    },

  abrirModal_servicio(data={}){
   this.modal_servicio=true;  
   if(this.modificar_servicio){
    this.tituloModal_servicio="Modificar Servicio";
    this.codigo_servicio=data.codigo_servicio,
    this.servicio.descripcion_servicio=data.descripcion_servicio;
   }else{
    this.codigo_servicio = 0;
    this.tituloModal_servicio="Agregar Servicio";
    this.servicio.descripcion_servicio='';
  
   }
  },

  //modal end


 //Agregar servicio

 async add_edit_servicio (){
     if(this.modificar_servicio){
     
     try{

      const servicio_ = {
        descripcion_servicio: this.servicio.descripcion_servicio,
      };

      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/servicio/' + this.codigo_servicio;

      const res = await axios.put(link, servicio_, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Actualizado correctamente',
      text: 'Se modifico la Servicio correctamente',
    });

  } catch (error) {
    if (error.response && error.response.status === 500) {
     const errors = error.response.data.erros;
     let errorMessage = '';
     for (const key in errors) {
      if (errors.hasOwnProperty(key)){
        errorMessage += `${errors[key][0]}\n`;
      }
     }
     Swal.fire({
      icon: 'error',
      title: '¡Error ya existe esa Servicio!',
      text: errorMessage,
     });

    } else {

      Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error,
     });


    }

  }

  this.cerrarModal_servicio();
  this.listar_servicio();

     } else {

     try{
       
      const servicio_ = {
        descripcion_servicio: this.servicio.descripcion_servicio,
      };


      const token = localStorage.getItem('token');
      const link = 'https://www.desarrolladorhansluyo.com/sistemas/cliente_gen_cold/api_admin_gen_cold_laravel/public/api/agregar_servicio';

      const res = await axios.post(link, servicio_, {
      headers: {
        Authorization: `Bearer ${token}`, // Autenticación usando token
      },
      withCredentials: true, // Envío de credenciales
    });

      Swal.fire({
      icon: 'success',
      title: 'Agregado correctamente',
      text: 'Se agrego el Servicio correctamente',
    });

     } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data && error.response.data.message ){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
      });

      } else {
        console.error('Error en la solicitud:',error);
      }

     }

     }
     this.cerrarModal_servicio();
     this.listar_servicio();
   },


 //end servicios

 // Inicia un intervalo para cada función
 iniciarIntervalo(funcion, tiempo) {
    const id = setInterval(funcion, tiempo);
    this.intervalIds.push(id);
  },
  // Detiene todos los intervalos
  detenerIntervalos() {
    this.intervalIds.forEach(id => clearInterval(id));
    this.intervalIds = [];
  },



  },

  created() {
   
    this.selector_categoria();
    setInterval(this.selector_categoria, 5000);

    this.marcas_selector();
    setInterval(this.marcas_selector, 5000);

    this.selector_estado_producto();
    setInterval(this.selector_estado_producto, 5000);

},



watch: {
  // Observa cuando se cambia la visibilidad de cada formulario
  frm_servicios(newVal) {
    if (newVal) {
      // Inicia el intervalo si se muestra el formulario
      this.iniciarIntervalo(this.listar_servicio, 5000);
    } else {
      // Detiene el intervalo si el formulario ya no está visible
      this.detenerIntervalos();
    }
  },
  frm_marca(newVal) {
    if (newVal) {
      this.iniciarIntervalo(this.listar_marca, 5000);
    } else {
      this.detenerIntervalos();
    }
  },
  frm_categoria_producto(newVal) {
    if (newVal) {
      this.iniciarIntervalo(this.listar_categoria_producto, 5000);
    } else {
      this.detenerIntervalos();
    }
  },
  frm_estado_producto(newVal) {
    if (newVal) {
      this.iniciarIntervalo(this.listar_estado_producto, 5000);
    } else {
      this.detenerIntervalos();
    }
  },
  frm_contenedores(newVal) {
    if (newVal) {
      this.iniciarIntervalo(this.listar_contenedor, 5000);
    } else {
      this.detenerIntervalos();
    }
  },
 
 
  // Agregar watchers para otros formularios si es necesario
},



};
</script>

<style scoped>
.header-cell {
  color: green !important; /* Asegúrate de que se aplique el color verde */
  font-weight: bold; /* Haz que el texto sea más visible */
}

.pagination button{
  margin:0 5px;
}

.lista-usuario-input{
 display: flex;
 flex-direction: row;
 justify-content: start;
 align-items: center;
 margin-left: 20px; 
}

.lista-usuario-ipt{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  margin-left: 20px; 
  flex: 1; /* Esto permite que el input ocupe el espacio restante */
  min-width: 200px; /* Ajusta el valor según sea necesario */
}

.accion-col {
  text-align: center; /* Centra el contenido horizontalmente */
}

.d-flex {
  display: flex;
  align-items: center; /* Opcional: Alinea verticalmente los elementos en el centro */
}

.d-flex > * {
  margin-right: 10px; /* Añade espacio entre los elementos */
}

.d-flex > *:last-child {
  margin-right: 0; /* Elimina el margen en el último elemento */
}

.imagen-tabla {
  width: 100px;
  height: 100px;
  object-fit: cover; /* Ajusta la imagen para llenar el espacio */
  display: block;    /* Evita márgenes o comportamiento extraño */
}

.my-checkbox .custom-control-label::before {
  border-color: #007bff; /* Cambia el color del borde */
}
.my-checkbox .custom-control-label::after {
  background-color: #007bff; /* Cambia el color del tick */
}
.my-checkbox .custom-control-input {
  transform: scale(4); /* Ajusta el tamaño del checkbox */
}
</style>